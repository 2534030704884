import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Injectable()
export class ContentService {
	constructor(private http: HttpClient) { }

    getCancellation(token) {
		return this.http.get('cancellation/', {
			headers: new HttpHeaders().set('Authorization', 'JWT ' + token)
		})
	}
}