import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Injectable()
export class UserService {
	constructor(private http: HttpClient) { }

	getUserChoices() {
		return this.http.get('choices/');
	}

	getUserAccount(token) {
		return this.http.get('account/', {
			headers: new HttpHeaders().set('Authorization', 'JWT ' + token)
		})
	}

	updateAccountInfo(token, obj) {
		return this.http.patch('account/', obj, {
			headers: new HttpHeaders().set('Authorization', 'JWT ' + token)
		})
	}

	checkInstagramAccount(inst) {
		return this.http.post(`account/check-instagram-url/`, {
			instagram_url: inst
		})
	}

	checkFacebookAccount(fb) {
		return this.http.post(`account/check-facebook-url/`, {
			facebook_url: fb
		})
	}

	unlinkFacebook(token) {
		return this.http.delete('account/facebook-url/', {
			headers: new HttpHeaders().set('Authorization', 'JWT ' + token)
		});
	}

	unlinkInstagram(token) {
		return this.http.delete('account/instagram-url/', {
			headers: new HttpHeaders().set('Authorization', 'JWT ' + token)
		});
	}

	linkInstagram(token, instagram_username, social_followers_instagram, social_avatar_url) {
		return this.http.post('account/instagram-url/', {
			instagram_username: instagram_username,
			social_followers_instagram: social_followers_instagram,
			social_avatar_url
		}, {
			headers: new HttpHeaders().set('Authorization', 'JWT ' + token)
		});
	}

	linkFacebook(token, facebook_url) {
		return this.http.post('account/facebook-url/', {
			facebook_url: facebook_url
		}, {
			headers: new HttpHeaders().set('Authorization', 'JWT ' + token)
		});
	}

	changeAvatar(token, photo_id) {
		return this.http.post('account/avatar/', { social_avatar_file: photo_id }, {
			headers: new HttpHeaders().set('Authorization', 'JWT ' + token)
		});
	}

	changePassword(token, old_pass, new_pass) {
		return this.http.post('account/change-password/', {
			old_password: old_pass,
			new_password: new_pass
		}, {
			headers: new HttpHeaders().set('Authorization', 'JWT ' + token)
		})
	}

	changeEmail(token, email) {
		return this.http.post('registration/request-email-change/', {
			email: email
		}, {
			headers: new HttpHeaders().set('Authorization', 'JWT ' + token)
		})
	}

	confirmEmailChange(token: string, confirm_token: string) {
		return this.http.post('account/confirm-email-change/', { token: confirm_token }, {
			headers: new HttpHeaders().set('Authorization', 'JWT ' + token)
		});
	}

	changeEmailAfterConfirmation(token, email) {
		return this.http.post('account/request-email-change/', {
			email: email
		}, {
			headers: new HttpHeaders().set('Authorization', 'JWT ' + token)
		})
	}

	uploadImage(token, file: Object) {
		return this.http.post('upload-images/', file, {
			headers: new HttpHeaders().set('Authorization', 'JWT ' + token)
		})
	}

	getSupplierAccount(token) {
		return this.http.get('supplier/', {
			headers: new HttpHeaders().set('Authorization', 'JWT ' + token)
		})
	}

	updateSupplierAccount(token, obj) {
		return this.http.patch('supplier/', obj, {
			headers: new HttpHeaders().set('Authorization', 'JWT ' + token)
		})
	}

	getProducts(token, limit, offset, query?) {
		return this.http.get(`products/?limit=${limit}&offset=${offset}${query}`, {
			headers: new HttpHeaders().set('Authorization', 'JWT ' + token)
		})
	}

	getProductsOfCategory(token, limit, offset, cat_id, query?) {
		return this.http.get(`products/?limit=${limit}&offset=${offset}&cat_id=${cat_id}${query}`, {
			headers: new HttpHeaders().set('Authorization', 'JWT ' + token)
		})
	}

	getNewProducts(token, limit, offset, query) {
		return this.http.get(`products/?limit=${limit}&offset=${offset}&new=true${query}`, {
			headers: new HttpHeaders().set('Authorization', 'JWT ' + token)
		})
	}

	getTopProducts(token, limit, offset, query) {
		return this.http.get(`products/?limit=${limit}&offset=${offset}&order=best_selling${query}`, {
			headers: new HttpHeaders().set('Authorization', 'JWT ' + token)
		})
	}

	getDealProduct(token, limit, offset, query) {
		return this.http.get(`products/?limit=${limit}&offset=${offset}&type=influencerdeals${query}`, {
			headers: new HttpHeaders().set('Authorization', 'JWT ' + token)
		})
	}

	getBrands(token, categories = '', query = '') {
		return this.http.get(`supplier_list/?categories=${categories}&${query}`, {
			headers: new HttpHeaders().set('Authorization', 'JWT ' + token)
		})
	}

	getBrand(token, id) {
		return this.http.get(`supplier/${id}/`, {
			headers: new HttpHeaders().set('Authorization', 'JWT ' + token)
		})
	}

	getAddresses(token) {
		return this.http.get('/address/', {
			headers: new HttpHeaders().set('Authorization', 'JWT ' + token)
		})
	}

	updateAddress(token, id, obj) {
		return this.http.patch(`/address/${id}/`, obj, {
			headers: new HttpHeaders().set('Authorization', 'JWT ' + token)
		})
	}

	saveAddress(token, obj) {
		return this.http.post(`/address/`, obj, {
			headers: new HttpHeaders().set('Authorization', 'JWT ' + token)
		})
	}

	deleteAddress(token, id) {
		return this.http.delete(`/address/${id}/`, {
			headers: new HttpHeaders().set('Authorization', 'JWT ' + token)
		})
	}

	getOrders(token, query = '') {
		return this.http.get(`orders/${query}`, {
			headers: new HttpHeaders().set('Authorization', 'JWT ' + token)
		})
	}

	getOrder(token, id) {
		return this.http.get(`orders/${id}`, {
			headers: new HttpHeaders().set('Authorization', 'JWT ' + token)
		})
	}

	exportOrders(token) {
		return this.http.get('orders-export/', {
			headers: new HttpHeaders({ 'Authorization': 'JWT ' + token }),
			responseType: 'blob'
		})
	}

	getPostsOfOrder(token, id) {
		return this.http.get(`posts/order/${id}`, {
			headers: new HttpHeaders().set('Authorization', 'JWT ' + token)
		})
	}

	getPosts(token) {
		return this.http.get('posts/', {
			headers: new HttpHeaders().set('Authorization', 'JWT ' + token)
		})
	}

	sendLink(token, id, link) {
		return this.http.patch(`posts/${id}/`, { link: link }, {
			headers: new HttpHeaders().set('Authorization', 'JWT ' + token)
		})
	}

	subscribeToNewsletter(email) {
		return this.http.post('newsletter_recipients/', {
			email: email
		})
	}

	getNotifications(token, show_read = 'False') {
		return this.http.get(`notifications?show_read=${show_read}`, {
			headers: new HttpHeaders().set('Authorization', 'JWT ' + token)
		})
	}

	markNotificationAsRead(token, id) {
		return this.http.patch(`notifications/mark_as_read/${id}`, { is_read: true }, {
			headers: new HttpHeaders().set('Authorization', 'JWT ' + token)
		})
	}

	editBrandInfo(token, id, obj) {
		return this.http.patch(`brands/${id}/`, obj, {
			headers: new HttpHeaders().set('Authorization', 'JWT ' + token)
		})
	}

	fillFacebookUrl(token, url) {
		return this.http.post('registration/fill-facebook-url/', {
			facebook_url: url
		}, {
			headers: new HttpHeaders().set('Authorization', 'JWT ' + token)
		})
	}

}