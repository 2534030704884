import { isPlatformBrowser } from '@angular/common';
//import { UserService } from './user';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, PLATFORM_ID, Inject } from "@angular/core";

const SCORER_LANG = 'LANG_CODE';
const STORAGE_KEY_AUTH_USER = 'SCORER_CURRENT_USER';
@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  public languages: any = [];
  private language = new BehaviorSubject('de');
  public currentLanguage = this.language.asObservable();

  public translations: any;

  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: Object
    //   private userService: UserService
  ) { }

  getTranslation(lang_code) {
    this.http.get(`../../assets/lang/scorer_${lang_code}.json`).subscribe(data => {
      this.translations = data;
    }, error => {
    });
  }

  translate(key: string): string {
    if (this.translations) {
      if (this.translations[key]) {
        return this.translations[key];
      }
      else {
        return "";
      }
    }
  }

  changeLanguage(lang_code) {
    this.language.next(lang_code);
    this.setLanguage(lang_code);
    this.changeUserLanguage(lang_code);
  }

  setLanguage(lang_code) {
    if (isPlatformBrowser(this.platformId)) {
     localStorage.setItem(SCORER_LANG, lang_code);
     this.getTranslation(lang_code);
    }
  }

  getLanguage() {
    if (isPlatformBrowser(this.platformId)) {
      let lang = localStorage.getItem(SCORER_LANG);
      if (lang) {
        this.language.next(lang);
        return lang;
      }
      else {
        this.language.next('de');
        return 'de'
      }
    } else return 'de';
  }

  changeUserLanguage(lang: string) {
    if (lang == 'en') {
      lang = 'en-us';
    }
    let user = JSON.parse(localStorage.getItem(STORAGE_KEY_AUTH_USER))
    // request returns 403 if account is not verified: Your account must be verified to be able to edit own profile
    if (user) {
      let token = JSON.parse(localStorage.getItem(STORAGE_KEY_AUTH_USER) || "null")['token']
      this.http.patch(`account/language/`, { language: lang }, {
        headers: new HttpHeaders().set('Authorization', 'JWT ' + token)
      }).subscribe(_ => { })
    }
  }
}

export class TranslationSet {
  public values: { [key: string]: string } = {};
}