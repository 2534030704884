import { FormService } from './../../../_services/form';
import { TranslationService } from './../../../_services/translation';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ModalService } from './../../../_services/modal';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { AuthenticationService } from './../../../_services/authentification';
import { UserService } from './../../../_services/user';
import { Component, OnInit } from '@angular/core';
import { CustomValidator } from '../../../_services/validators';

@Component({
	selector: 'home-header',
	templateUrl: './home-header.component.html',
	styleUrls: ['./home-header.component.sass']
})
export class HomeHeaderComponent implements OnInit {
	constructor(
		private authenticationService: AuthenticationService,
		private router: Router,
		private modalService: ModalService,
		private userService: UserService,
		private route: ActivatedRoute,
		private translationService: TranslationService,
		private formService: FormService
	) { }

	form = new FormGroup({
		info_text: new FormControl('', [Validators.required]),
		info_text_de: new FormControl('', [Validators.required]),
		promo_images: new FormControl([], [CustomValidator.images])
	});

	legal_form = new FormGroup({
		supplier: new FormGroup({
			company_name: new FormControl('', [Validators.required]),
			//category: new FormControl([], [Validators.required]),
			web_address: new FormControl(''),
			country: new FormControl('', [Validators.required]),
			zip_code: new FormControl('', [Validators.maxLength(10), Validators.required]),
			city: new FormControl('', [Validators.required]),
			street_address: new FormControl('', [Validators.required]),
			street_address2: new FormControl(''),
			vat_id: new FormControl('', [Validators.required]),
			tax_id: new FormControl(''),
			diverging_delivery_address: new FormControl(''),
			cp_name: new FormControl(''),
			cp_email: new FormControl('', [CustomValidator.optionalEmail]),
			cp_phone_number: new FormControl(''),
			cp_substitute: new FormControl(''),
		})
	})

	scope: string = '';
	showDropdown: boolean = false;
	isFormLoading: boolean = false;
	isSubmitted: boolean = false;
	isSaving: boolean = false;
	url: string = '';

	notifications: any = [];
	isNewNotif: boolean = false;
	isNotifOpen: boolean = false;

	currentLanguage: string = 'en';

	changeLanguage(lang_code: string) {
		this.translationService.changeLanguage(lang_code);
		this.currentLanguage = this.translationService.getLanguage();
	}

	ngOnInit(): void {
		this.translationService.currentLanguage.subscribe(value => {
			this.currentLanguage = value;
		})
		var user = this.authenticationService.getCurrentUser();
		if (user) {
			this.scope = user.scope[0].match(/([^:]+)/)[0];
		}

		this.getNotifications();

		this.url = this.router.url;
		this.router.events.subscribe((e) => {
			if (e instanceof NavigationEnd) {
				this.url = e.url;
			}
		});
	}

	getNotifications() {
		this.userService.getNotifications(this.authenticationService.getCurrentUserToken(), 'True')
			.subscribe((data: Array<any>) => {
				this.notifications = data.sort((a, b) => b.id - a.id);
				this.isNewNotif = this.notifications.some(notif => !notif.is_read);
			})
	}

	readNotifications() {
		this.isNotifOpen = !this.isNotifOpen;
		if (this.isNotifOpen) {
			this.notifications.filter(notif => !notif.is_read).forEach(notif => {
				this.userService.markNotificationAsRead(this.authenticationService.getCurrentUserToken(), notif.id)
					.subscribe(_ => {
						setTimeout(() => {
							this.isNewNotif = false;
							this.getNotifications();
						}, 1500)
					})
			})
		}
	}

	logout() {
		this.authenticationService.logout();
		this.router.navigate(['/brand/login']);
	}

	editBrand() {
		this.isFormLoading = true;
		this.modalService.open('edit-brand');
		let user = this.authenticationService.getCurrentUser();
		this.form.patchValue(user.user['supplier']);
		this.isFormLoading = false;
	}

	closeModal(id) {
		this.modalService.close(id);
		this.isSubmitted = false;
		this.isFormLoading = false;
	}

	onBrandUpdate() {
		let obj = {};
		obj['supplier'] = Object.assign({}, this.form.value);
		obj['supplier']['promo_images_id'] = this.form.controls.promo_images.value.filter(e => e).map(e => e.id);
		this.userService.updateSupplierAccount(this.authenticationService.getCurrentUserToken(), obj)
			.subscribe(_ => {
				this.isSubmitted = true;
				let user = this.authenticationService.getCurrentUser();
				user.user['supplier']['info_text'] = _['supplier']['info_text'];
				user.user['supplier']['info_text_de'] = _['supplier']['info_text_de'];
				user.user['supplier']['promo_images'] = _['supplier']['promo_images'];
				this.authenticationService.saveCurrentUserAuthInfo(user);
				this.form.controls.promo_images.setValue([]);
			})
	}

	editLegal() {
		this.isFormLoading = true;
		this.modalService.open('edit-legal');
		this.userService.getSupplierAccount(this.authenticationService.getCurrentUserToken())
			.subscribe(data => {
				this.legal_form.patchValue(data);
				this.isFormLoading = false;
			}, err => this.isFormLoading = false)
	}

	onLegalUpdate() {
		this.isSaving = true;
		if (!this.legal_form.valid) {
			this.formService.markTouched(this.legal_form);
			this.isSaving = false;
			return;
		}
		this.userService.updateSupplierAccount(this.authenticationService.getCurrentUserToken(), this.legal_form.value)
			.subscribe((data: any) => {
				this.isSubmitted = true;
				let user = this.authenticationService.getCurrentUser();
				user.user = Object.assign({}, data);
				this.authenticationService.saveCurrentUserAuthInfo(user);
				this.isSaving = false;
			}, err => this.isSaving = false)
	}

	onBgClick(id: string, e: any) {
		if (!$(e.target).closest('.modal-body').length)
			this.closeModal(id);
	}

	closeNav() {
        $('#overlay-nav').css('width', '0');
    }

    openNav() {
        $('#overlay-nav').css('width', '100%');
    }
}
