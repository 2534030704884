import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class BrandService {
    constructor(private http: HttpClient) { }

    getBrandCategories(token) {
        return this.http.get('categories/', {
            headers: new HttpHeaders().set('Authorization', 'JWT ' + token)
        })
    }

    addProduct(token, obj) {
        return this.http.post('products/', obj, {
            headers: new HttpHeaders().set('Authorization', 'JWT ' + token)
        })
    }

    getProductById(token, id) {
        return this.http.get(`products/${id}/`, {
            headers: new HttpHeaders().set('Authorization', 'JWT ' + token)
        })
    }

    updateProduct(token, id, obj) {
        return this.http.patch(`products/${id}`, obj, {
            headers: new HttpHeaders().set('Authorization', 'JWT ' + token)
        })
    }

    deleteProduct(token, id) {
        return this.http.delete(`products/${id}`, {
            headers: new HttpHeaders().set('Authorization', 'JWT ' + token)
        })
    }

    
    getProductsOfCategory(token, cat_id, limit, offset, query = '') {
        return this.http.get(`products?limit=${limit}&offset=${offset}&cat_id=${cat_id}&is_active=True&my_products=true${query}`, {
            headers: new HttpHeaders().set('Authorization', 'JWT ' + token)
        })
    }

    getProducts(token) {
        return this.http.get('products?limit=12&offset=0&is_active=True&my_products=true', {
            headers: new HttpHeaders().set('Authorization', 'JWT ' + token)
        })
    }

    getRecentProducts(token) {
        return this.http.get('products?limit=12&offset=0&my_products=true&order=date_upd_desc', {
            headers: new HttpHeaders().set('Authorization', 'JWT ' + token)
        })
    }

    getArchivedProducts(token, limit, offset, query = '') {
        return this.http.get(`products?limit=${limit}&offset=${offset}&is_active=False&my_products=true${query}`, {
            headers: new HttpHeaders().set('Authorization', 'JWT ' + token)
        })
    }

    archiveProduct(token, id) {
        return this.http.delete(`/product_activation/${id}/`, {
            headers: new HttpHeaders().set('Authorization', 'JWT ' + token)
        })
    }

    unarchiveProduct(token, id) {
        return this.http.post(`/product_activation/${id}/`, {}, {
            headers: new HttpHeaders().set('Authorization', 'JWT ' + token)
        })
    }

    deactivateCategory(token, id) {
        return this.http.patch(`products/deactivate_category/${id}/`, {}, {
            headers: new HttpHeaders().set('Authorization', 'JWT ' + token)
        })
    }

}
