import { BehaviorSubject } from 'rxjs';
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';

const STORAGE_KEY_CART = 'SCORER_CART';

@Injectable()
export class CartService {
    constructor(private http: HttpClient) { }
    private items = new BehaviorSubject(this.getCurrentCart());
    currentItems = this.items.asObservable();

    getItems() {
        return this.items.value;
    }

    addItem(item: any, options: any) {
        const currentValue = this.getCurrentCart();
        var itemToAdd = {};
        itemToAdd = Object.assign(itemToAdd, item)
        itemToAdd['options'] = options;

        let existent = currentValue.find(x => x.id === item.id && x.options.option === 'buy' && options.option === 'buy');
        let excludedAmount = {};
        if (existent) {
            excludedAmount = JSON.parse(JSON.stringify(existent))
            delete excludedAmount['options'].amount;
        }
        if (existent && JSON.stringify(excludedAmount['options']) === JSON.stringify(options)) {
            existent.options.amount++;
            this.items.next(currentValue);
        }
        else {
            itemToAdd['options'].amount = 1;
            const updatedValue = [...currentValue, itemToAdd];
            this.items.next(updatedValue);
        }
        this.saveCurrentCart();
    }

    removeItem(id: number, options) {
        const currentValue = this.getCurrentCart();
        let index = currentValue.findIndex(x => x.id === id && JSON.stringify(x.options) == JSON.stringify(options));
        currentValue.splice(index, 1);
        const updatedValue = [...currentValue];
        this.items.next(updatedValue);
        if (this.items.value.length < 1) {
            this.hide();
        }
        this.saveCurrentCart();
    }

    getAmountOfItem(id: number) {
        const currentValue = this.getCurrentCart();
        // let value = currentValue.find(x => x.options.option === 'buy' && x.id === id);
        let sum = 0;
        let value = currentValue.filter(x => x.options.option === 'buy' && x.id === id).map(e => sum += e.options.amount);
        return sum;
    }

    increaseAmount(id: number, options) {
        const currentValue = this.getCurrentCart();
        let value = currentValue.find(x => x.options.option === 'buy' && x.id === id && JSON.stringify(x.options) == JSON.stringify(options));
        if (value) value.options.amount++;
        this.items.next(currentValue);
        this.saveCurrentCart();
    }

    getItemInfo(id: number, option: string) {
        const currentValue = this.getCurrentCart();
        let value = currentValue.find(x => x.options.option === option && x.id === id);
        return value;
    }

    descreaseAmount(id: number, options) {
        const currentValue = this.getCurrentCart();
        let index = currentValue.findIndex(x => x.id === id && x.options.option === 'buy' && JSON.stringify(x.options) == JSON.stringify(options));
        let value = currentValue.find(x => x.options.option === 'buy' && x.id === id && JSON.stringify(x.options) == JSON.stringify(options));
        if (value) value.options.amount--;
        if (value.options.amount < 1) {
            currentValue.splice(index, 1);
        }
        this.items.next(currentValue);
        this.saveCurrentCart();
    }

    saveCurrentCart() {
        localStorage.setItem(STORAGE_KEY_CART, JSON.stringify(this.items.value))
    }

    getCurrentCart() {
        return JSON.parse(localStorage.getItem(STORAGE_KEY_CART) || '[]');
    }

    removeAllItems() {
        this.items.next([]);
    }

    hide() {
        $('.cart-bg').css('display', 'none');
    }
    openCartPreview() {
        $('.cart-bg').css('display', 'block');
        $('.cart-bg').click(function (e) {
            $('.cart-bg').css('display', 'none')
            e.stopPropagation();
        }
        ).children().click(function (e) {
            return false;
        });;
    }

    createOrder(token, obj) {
        return this.http.post('orders/', obj, {
            headers: new HttpHeaders().set('Authorization', 'JWT ' + token)
        })
    }

    getPaymentUrl(token, id) {
        return this.http.post('payments/get_redirect_url', { order_id: id }, {
            headers: new HttpHeaders().set('Authorization', 'JWT ' + token)
        })
    }

    getStripeDetails(token, id) {
        return this.http.get(`payments/get_stripe_details/${id}/`, {
            headers: new HttpHeaders().set('Authorization', 'JWT ' + token)
        })
    }

    getPayPalDetails(token, id) {
        return this.http.get(`payments/get_paypal_details/${id}/`, {
            headers: new HttpHeaders().set('Authorization', 'JWT ' + token)
        })
    }

    processPayment(token, id, session_id) {
        return this.http.post(`payments/process_payment`, { order_id: id, session_id: session_id }, {
            headers: new HttpHeaders().set('Authorization', 'JWT ' + token)
        })
    }

    processPaypal(token, id, paypal_name, paypal_email, paypal_id, paypal_order_id) {
        return this.http.post(`payments/process_paypal`,
            { order_id: id, paypal_id: paypal_id, paypal_name: paypal_name, paypal_email: paypal_email, paypal_order_id: paypal_order_id }, {
            headers: new HttpHeaders().set('Authorization', 'JWT ' + token)
        })
    }

    processDeal(token, id) {
        return this.http.post(`payments/process_deal`, { order_id: id }, {
            headers: new HttpHeaders().set('Authorization', 'JWT ' + token)
        })
    }

}