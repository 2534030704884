// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl() {
    //return window.location.origin + "/";
    //return "http://localhost:8000/";
    //return "https://www.scorerclub.com/";
    return "https://staging.scorerclub.com/";
  },
  instagramAppId: '2446285868983417',
  secretkey: '53b649c6229c843dda7a8140eafa0944',
  facebookAppId: '2238191463119326',
  redirecturl: window.location.origin
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
