import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-gallery',
    templateUrl: './gallery-slider.component.html',
    styleUrls: ['./gallery-slider.component.sass']
})
export class GallerySliderComponent implements OnInit {
    constructor() { }
    @Input() images: any = [];
    currentImageIndex: number = 0;

    ngOnInit(): void { }

    setCurrentImage(index) {
        this.currentImageIndex = index;
    }

    selectNextImage() {
        if(this.currentImageIndex < this.images.length - 1)
            this.currentImageIndex++;
        else 
            this.currentImageIndex = 0;
    }

    selectPreviousImage() {
        if(this.currentImageIndex === 0)
            this.currentImageIndex = this.images.length - 1;
        else 
            this.currentImageIndex--;
    }
}
