import { TranslationService } from './../../../_services/translation';
import { AbstractControl, ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter, Optional, Host, SkipSelf, forwardRef } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Component({
    selector: 'app-input-percent',
    templateUrl: './input-percent.component.html',
    styleUrls: ['./input-percent.component.sass'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputPercentComponent),
            multi: true
        }
    ]
})
export class InputPercentComponent implements ControlValueAccessor, OnInit {
    constructor(
        @Optional() @Host() @SkipSelf()
        private controlContainer: ControlContainer,
        private translationService: TranslationService,
        private decimalPipe: DecimalPipe
    ) { }

    inputText = '';
    currentLanguage: string = 'en';
    @Input() formControlName: string;
    @Input() label = '';
    @Input() placeholder = "0%";

    @Output() private valueChange = new EventEmitter();
    control: AbstractControl;

    ngOnInit(): void {
        this.translationService.currentLanguage.subscribe(value => {
            this.currentLanguage = value;
        })
        var self = this;
        // $("input[data-type='percent']").on({
        //     keyup: function() {
        //       self.formatCurrency($(this), "keyup");
        //     },
        //     blur: function() { 
        //       self.formatCurrency($(this), "blur");
        //     }
        // });
        
        if (this.controlContainer) {
            if (this.formControlName) {
                this.control = this.controlContainer.control.get(this.formControlName);
                this.control.valueChanges.subscribe(x => {
                })
            } else {
                console.warn('Missing FormControlName directive from host element of the component');
            }
     }
    }

    onKeyPressed(event: any) {
        var pattern;
        if(this.currentLanguage === 'en') {
            pattern = /^[0-9.]$/;
        }
        else {
            pattern = /^[0-9,]$/;
        }
        let inputChar = String.fromCharCode(event.charCode);
        if (!pattern.test(inputChar)) {
            event.preventDefault();
        }
    }

    writeValue(value: any) {
        if (value !== undefined) {
            this.inputText = this.decimalPipe.transform(value, '0.2', this.currentLanguage)
        }
    }

    onInputChange(){
        this.propagateChange(+this.inputText.split("%")[0].replace(/[,]/g, '.'));
    }

    propagateChange = (_: any) => {};

    registerOnChange(fn) {
        this.propagateChange = fn;
    }

    registerOnTouched() {}

    formatNumber(n) {
        // format number 1000000 to 1,234,567
        return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
      
      
    formatCurrency(input, blur) {
        var input_val = input.val();
        if (input_val === "") { return; }
        if (input_val === "%") {
            var strLength = input.val().length * 2;
            input[0].setSelectionRange(strLength, strLength);
            input.val('');
        }
        else {
            var caret_pos = input.prop("selectionStart");
            var original_len = input_val.length;
            input.val(function(index, old) { 
                return old.replace(/[^0-9]/g, '') + '%'; 
            });
            var updated_len = input_val.length;
            caret_pos = updated_len - original_len + caret_pos;
            input[0].setSelectionRange(caret_pos, caret_pos);
        }
        // var original_len = input_val.length;
        // 
        // if (input_val.indexOf(".") >= 0) {
        //     var decimal_pos = input_val.indexOf(".");
        
        //     // split number by decimal point
        //     var left_side = input_val.substring(0, decimal_pos);
        //     var right_side = input_val.substring(decimal_pos);
        
        //     // add commas to left side of number
        //     left_side = this.formatNumber(left_side);
        
        //     // validate right side
        //     right_side = this.formatNumber(right_side);
            
        //     // On blur make sure 2 numbers after decimal
        //     if (blur === "blur") {
        //     right_side += "00";
        //     }
            
        //     // Limit decimal to only 2 digits
        //     right_side = right_side.substring(0, 2);
        //     input_val = "$" + left_side + "." + right_side;
        
        // } else {
        //     // no decimal entered
        //     // add commas to number
        //     // remove all non-digits
        //     input_val = this.formatNumber(input_val);
        //     input_val = "$" + input_val;
            
        //     // final formatting
        //     if (blur === "blur") {
        //     input_val += ".00";
        //     }
        // }
        
        // // send updated string to input
        // input.val(input_val);
        
        // // put caret back in the right position
        // var updated_len = input_val.length;
        // caret_pos = updated_len - original_len + caret_pos;
        // input[0].setSelectionRange(caret_pos, caret_pos);
    }
}
