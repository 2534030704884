import { environment } from './../environments/environment';
import { CookieService } from './_services/cookie';
import { ModalService } from './_services/modal';
import { TranslationService } from './_services/translation';
import { PreviousRouteService } from './_services/previous-route';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit, AfterViewInit {
  url = '';
  title = 'ScorerClub';
  currentLanguage: string = 'en';
  constructor(
    private router: Router,
    private prevRoute: PreviousRouteService,
    private translationService: TranslationService,
    private modalService: ModalService,
    private cookieService: CookieService
  ) {
    registerLocaleData(localeDe, 'de');
  }

  ngOnInit() {
    this.currentLanguage = this.translationService.getLanguage();
    this.translationService.changeLanguage(this.currentLanguage);
    this.translationService.currentLanguage.subscribe(value => {
      this.currentLanguage = value;
    })
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.url = e.url;
      }
    });
  }

  ngAfterViewInit(): void {
    if (!this.cookieService.getCookie('cookies')) {
      let cookiesPopup = document.querySelector('.cookies-popup');
      setTimeout(function () {
        cookiesPopup.setAttribute('style', 'height: auto; padding: 24px 56px; visibility: visible')
      }, 1000);
    }
  }

  acceptCookies() {
    this.cookieService.setCookie('cookies', 'true', 356);
    this.closeCookies();
  }

  closeCookies() {
    let cookiesPopup = document.querySelector('.cookies-popup');
    cookiesPopup.setAttribute('style', 'height: 0; padding: 0; visibility: hidden')
  }

  openCookies() {
    this.modalService.open('modal-privacy');
    const cookiesParagraph = document.querySelector('#modal-privacy #cookies');
    if (cookiesParagraph)
      cookiesParagraph.scrollIntoView({ behavior: 'smooth' });
  }
}
