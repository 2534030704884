import { FooterHomeComponent } from './../modules/footer-home/footer-home.component';
import { CartPreviewComponent } from './../main/shop/cart-preview/cart-preview.component';
import { DatepickerComponent } from './../components/form/date-picker/date-picker.component';
import { RouterModule } from '@angular/router';
import { PlayerComponent } from './../components/player/player.component';
import { WelcomeGiftComponent } from './../components/modals/welcome-gift/welcome-gift-component';
import { SizeItemsComponent } from './../components/form/size-items/size-items.component';
import { PrivacyPolicyComponent } from './../modules/privacy-policy/privacy-policy.component';
import { TermsComponent } from './../modules/terms/terms.component';
import { ProductCardComponent } from './../main/shop/components/product/product.component';
import { SelectMultipleComponent } from './../components/form/select-multiple/select-multiple.component';
import { SelectSortComponent } from './../components/form/select-sort/select-sort.component';
import { CustomCheckboxComponent } from './../components/form/custom-checkbox/custom-checkbox.component';
import { PaginationComponent } from './../components/pagination/pagination.component';
import { FiltersComponent } from './../main/shop/filters/filters.component';
import { InputPercentComponent } from './../components/form/input-percent/input-percent.component';
import { ButtonLoadingComponent } from './../components/assets/button-loading/button-loading.component';
import { InputUrlComponent } from './../components/form/input-url/input-url.component';
import { TextareaComponent } from './../components/form/input-textarea/textarea.component';
import { CheckComponent } from './../components/assets/check/check.component';
import { AlertComponent } from './../_directives/alert.component';
import { GallerySliderComponent } from './../components/assets/gallery-slider/gallery-slider.component';
import { InputEmailComponent } from './../components/form/input-email/input-email.component';
import { InputPasswordComponent } from './../components/form/input-password/input-password.component';
import { AddButtonComponent } from './../components/assets/add-button/add-button.component';
import { EnvelopeComponent } from './../components/assets/envelope/envelope.component';
import { DateSelectComponent } from './../components/form/date-select/date-select.component';
import { CheckboxComponent } from './../components/form/checkbox/checkbox.component';
import { ColorPickerComponent } from './../components/form/color-picker/color-picker.component';
import { RadioComponent } from './../components/form/radio/radio.component';
import { SelectComponent } from './../components/form/select/select.component';
import { SelectImagesComponent } from './../components/form/select-images/select-images.component';
import { InputNumberSelectComponent } from './../components/form/input-number-select/input-number-select.component';
import { InputCurrencyComponent } from './../components/form/input-currency/input-currency.component';
import { InputTextComponent } from './../components/form/input-text/input-text.component';
import { ClickOutsideDirective } from './../_directives/click-outside.component';
import { SpinnerComponent } from './../components/assets/spinner/spinner.component';
import { CommonModule } from '@angular/common';
import { OrderModalComponent } from './../components/modals/order/order-modal.component';
import { ModalComponent } from './../_directives/modal.component';
import { BoldPipe } from './bold.pipe';
import { UnderlinePipe } from './underline.pipe';
import { SupPipe } from './sup.pipe';
import { TranslatePipe } from './translate';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OwlModule } from 'ngx-owl-carousel';
import { MatDatepickerModule } from '@angular/material/datepicker';
//import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { VgCoreModule } from 'videogular2/core';
import { VgControlsModule } from 'videogular2/controls';
import { VgOverlayPlayModule } from 'videogular2/overlay-play';
import { VgBufferingModule } from 'videogular2/buffering';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { LoginSupplierComponent } from '../login/supplier/login-supplier.component';
import { SafeHtmlPipe } from './safeHtml';
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        OwlModule,
        MatDatepickerModule,
        //BrowserModule,
        //BrowserAnimationsModule,
        MatFormFieldModule,
        MatInputModule,
        SlickCarouselModule,
        VgCoreModule,
        VgControlsModule,
        VgOverlayPlayModule,
        VgBufferingModule,
        DragDropModule,
        RouterModule,
        ReactiveFormsModule,
    ],
    declarations: [
        TranslatePipe,
        SupPipe,
        UnderlinePipe,
        BoldPipe,
        ModalComponent,
        OrderModalComponent,
        SpinnerComponent,
        ClickOutsideDirective,
        InputTextComponent,
        InputCurrencyComponent,
        InputNumberSelectComponent,
        SelectImagesComponent,
        SelectComponent,
        RadioComponent,
        ColorPickerComponent,
        CheckboxComponent,
        DateSelectComponent,
        EnvelopeComponent,
        AddButtonComponent,
        InputPasswordComponent,
        InputEmailComponent,
        GallerySliderComponent,
        AlertComponent,
        CheckComponent,
        TextareaComponent,
        InputUrlComponent,
        ButtonLoadingComponent,
        InputPercentComponent,
        FiltersComponent,
        PaginationComponent,
        CustomCheckboxComponent,
        SelectSortComponent,
        SelectMultipleComponent,
        ProductCardComponent,
        TermsComponent,
        PrivacyPolicyComponent,
        SizeItemsComponent,
        WelcomeGiftComponent,
        PlayerComponent,
        DatepickerComponent,
        CartPreviewComponent,
        FooterHomeComponent,
        LoginSupplierComponent,
        SafeHtmlPipe
    ],
    exports: [
        TranslatePipe,
        SupPipe,
        UnderlinePipe,
        BoldPipe,
        ModalComponent,
        OrderModalComponent,
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        SpinnerComponent,
        ClickOutsideDirective,
        InputTextComponent,
        InputCurrencyComponent,
        InputNumberSelectComponent,
        SelectImagesComponent,
        SelectComponent,
        RadioComponent,
        ColorPickerComponent,
        CheckboxComponent,
        DateSelectComponent,
        EnvelopeComponent,
        AddButtonComponent,
        InputPasswordComponent,
        InputEmailComponent,
        GallerySliderComponent,
        AlertComponent,
        CheckComponent,
        TextareaComponent,
        InputUrlComponent,
        ButtonLoadingComponent,
        InputPercentComponent,
        FiltersComponent,
        PaginationComponent,
        CustomCheckboxComponent,
        SelectSortComponent,
        SelectMultipleComponent,
        ProductCardComponent,
        TermsComponent,
        PrivacyPolicyComponent,
        SizeItemsComponent,
        WelcomeGiftComponent,
        PlayerComponent,
        OwlModule,
        MatDatepickerModule,
       // BrowserModule,
       // BrowserAnimationsModule,
        MatFormFieldModule,
        MatInputModule,
        SlickCarouselModule,
        VgCoreModule,
        VgControlsModule,
        VgOverlayPlayModule,
        VgBufferingModule,
        DragDropModule,
        RouterModule,
        DatepickerComponent,
        CartPreviewComponent,
        FooterHomeComponent,
        LoginSupplierComponent
    ],
    providers: [
        TranslatePipe,
        SupPipe,
        UnderlinePipe,
        BoldPipe
    ]
  })
  export class SharedModule { }