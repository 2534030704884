import { TranslationService } from './../../../_services/translation';
import { AuthenticationService } from './../../../_services/authentification';
import { UserService } from './../../../_services/user';
import { ModalService } from './../../../_services/modal';
import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'app-order-modal',
	templateUrl: './order-modal.component.html',
	styleUrls: ['./order-modal.component.sass']
})
export class OrderModalComponent implements OnInit {
	@Input() order: any;
	posts: any = [];
	showPosts: boolean = true;
	currentLanguage: string = 'en';
	userType: string = 'influencer'
	constructor(
		private modalService: ModalService,
		private userService: UserService,
		private authenticationService: AuthenticationService,
		private translationService: TranslationService
	) { }

	ngOnInit(): void {
		this.translationService.currentLanguage.subscribe(value => {
			this.currentLanguage = value;
		})
	 }


	close() {
		this.modalService.close('order-modal');
	}

	open(type?:string, id?: number) {
		this.userType = type;
		type === 'influencer' ? this.showPosts = false : this.showPosts = true;
		if(id) {
			this.getPostsOfOrder(id);
		}
		this.modalService.open('order-modal');
	}

	getPostsOfOrder(id) {
		this.userService.getPostsOfOrder(this.authenticationService.getCurrentUserToken(), id)
			.subscribe(data => {
				this.posts = data['results'];
			})
	}

	getOrderTotalPrice(items: any) {
		let sum = 0;
		items.forEach(item => {
			sum += +item.price_with_discount;
		})
		return sum;
	}

	getOrderDetails(order: any): string{
		let details: string = '';
		if (order.post_type){
			if (order.type == 'Influencer deal'){
				details = order.user_choice_max_posts;
				if (order.post_type == 'story')
					details += ' Story-Post';
				if (order.post_type == 'feed')
					details += ' Feed-Post';
				if (order.post_type == 'reel')
					details += ' Reel';
	
				if (order.user_choice_max_posts > 1){
					details += 's';
				}
			}
		}
		return details;
	}
}
