import { Router } from '@angular/router';
import {Injectable} from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';

@Injectable()
export class ReloadService {
    constructor(
        private router: Router
    ) { }
    private isReload = new BehaviorSubject('');
    reload = this.isReload.asObservable();

    doReload() {
        this.isReload.next(this.router.url);
    }
}
