import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';
import { TranslationService } from './../../../_services/translation';
import { Component, OnInit, Input, OnDestroy, Inject, PLATFORM_ID } from '@angular/core';

@Component({
	selector: 'app-welcome-gift',
	templateUrl: './welcome-gift.component.html',
	styleUrls: ['./welcome-gift.component.sass']
})
export class WelcomeGiftComponent implements OnInit, OnDestroy {
	constructor(
		private translationService: TranslationService,
		private router: Router,
		@Inject(PLATFORM_ID) private platformId: Object
	) { }

	@Input() type = 'register';
	currentLanguage: string = 'de';
	subscription: any;

	ngOnInit(): void {
		this.subscription = this.translationService.currentLanguage.subscribe(value => {
			this.currentLanguage = value;
		})
	}

	openJoin() {
		this.router.navigate(['registration'])
		// $('#welcome-gift').fadeOut("fast").css('display', 'none');
		// $('#join').fadeIn("fast").css('display', 'flex');
	}

	ngOnDestroy() {
		if(this.subscription) 
			this.subscription.unsubscribe();
	}

	close(e: Event) {
		if(isPlatformBrowser(this.platformId)) {
			$('#welcome-gift').fadeOut('fast');
		} else {
			let wlcm = document.getElementById('welcome-gift');
			wlcm.style.display = 'none';
		}
		// $('#welcome-gift').fadeOut('fast');
	}

}
