import { AuthServiceConfig, FacebookLoginProvider, LoginOpt } from 'angularx-social-login';
import {InstagramLoginProvider} from './socialProviders/instagramProvider';
import { environment } from '../../environments/environment'

const fbLoginOptions: LoginOpt = {
  scope: 'email, user_friends',
  return_scopes: true,
  enable_profile_selector: true
};

export function getAuthServiceConfigs()  {
  let config = new AuthServiceConfig([
    /*{
      id: FacebookLoginProvider.PROVIDER_ID,
      provider: new FacebookLoginProvider(environment.facebookAppId, fbLoginOptions)
    },*/
    {
      id: InstagramLoginProvider.PROVIDER_ID,
      provider: new InstagramLoginProvider(environment.instagramAppId, environment.secretkey, '/')
    }
  ]);
  return config;
}
