import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { text } from '@angular/core/src/render3';

@Component({
    selector: 'app-add-button',
    templateUrl: './add-button.component.html',
    styleUrls: ['./add-button.component.sass']
})
export class AddButtonComponent implements OnInit {

    @Input() text: string = "";
    @Output() onClick = new EventEmitter<any>();

    constructor() { }

    ngOnInit(): void { }

    onClickAdd() {
        this.onClick.emit();
    }
}
