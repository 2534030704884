import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-button-loading',
    templateUrl: './button-loading.component.html',
    styleUrls: ['./button-loading.component.sass']
})
export class ButtonLoadingComponent implements OnInit {
    constructor() { }

    ngOnInit(): void { }
}
