import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private http: HttpClient) { }

  getProductById(token, id) {
    return this.http.get(`products/${id}/`, {
      headers: new HttpHeaders().set('Authorization', 'JWT ' + token)
    })
  }

  getProductsOfBrand(token, id, limit, offset) {
    return this.http.get(`products/?brand_id=${id}&limit=${limit}&offset=${offset}`, {
      headers: new HttpHeaders().set('Authorization', 'JWT ' + token)
    })
  }

  targetGroup(token, obj) {
    return this.http.post(`target_group`, obj, {
      headers: new HttpHeaders().set('Authorization', 'JWT ' + token)
    })
  }

  getProductChoices(token) {
    return this.http.get('product_choices/', {
      headers: new HttpHeaders().set('Authorization', 'JWT ' + token)
    })
  }
}
