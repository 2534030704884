import { TranslationService } from './../../../_services/translation';
import { ProductService } from './../../../_services/product';
import { FilterService } from './../../../_services/filter';
import { UserService } from './../../../_services/user';
import { AuthenticationService } from './../../../_services/authentification';
import { BrandService } from './../../../_services/brand';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

export interface FilterParams {
	cat_id: Array<number>,
	brand_id: Array<number>
}

@Component({
	selector: 'app-filters',
	templateUrl: './filters.component.html',
	styleUrls: ['./filters.component.sass']
})
export class FiltersComponent implements OnInit {
	sub: any;
	constructor(
		public router: Router,
		private brandService: BrandService,
		private userService: UserService,
		private authenticationService: AuthenticationService,
		private filterService: FilterService,
		private productService: ProductService,
		private route: ActivatedRoute,
		private translationService: TranslationService,
	) {
	}

	cat: number;

	categories: any = [];
	categories_initial: any = [];
	brands: any = [];
	brands_initial: any = [];
	colors: any = [];
	colors_initial: any = [];
	sizes: any = [];
	sizes_initial: any = [];

	brand_id: any = [];
	color_id: any = [];
	size_id: any = [];
	cat_id: any = [];

	prevCatId: any = [];

	isBrandsCollapsed: boolean = true;
	isColorsCollapsed: boolean = true;

	currentLanguage: string = 'en';


	isLoading: boolean = false;
	prevBrands: any;
	prodCat: any;

	isOpened: boolean = false;
	isIos: boolean = false;

	ngOnInit(): void {
		if ((navigator.userAgent.toLowerCase().indexOf("ipad") > -1) ||
			(navigator.userAgent.toLowerCase().indexOf("iphone") > -1) ||
			(navigator.userAgent.toLowerCase().indexOf("ipod") > -1)) {
			this.isIos = true;
		}

		this.productService.getProductChoices(this.authenticationService.getCurrentUserToken())
			.subscribe(data => {
				this.colors = data['color'];
				this.colors_initial = data['color'];
				this.sizes = data['size'];
				this.sizes_initial = data['size'];
			})
		this.brandService.getBrandCategories(this.authenticationService.getCurrentUserToken())
			.subscribe((data: Array<any>) => {
				this.categories = data.sort((a, b) => a.id - b.id);
				this.categories_initial = data.sort((a, b) => a.id - b.id);
			})
		// this.userService.getBrands(this.authenticationService.getCurrentUserToken())
		// 	.subscribe((data: Array<any>) => {
		// 		this.brands = data.map(e => e.supplier);
		// 		this.brands_initial = data.map(e => e.supplier);
		// 		this.isLoading = false;
		// 	})
		this.translationService.currentLanguage.subscribe(value => {
			this.currentLanguage = value;
		})

		this.filterService.currentFilters.subscribe(value => {
			if (!value) {
				this.clearAll();
			}
		})

		this.filterService.isLoading.subscribe(value => {
			this.isLoading = value;
		})

		this.filterService.currentCategory.subscribe(cat => {
			this.cat = cat;
			this.cat_id = [];
			if (cat) {
				this.cat_id.push(cat);
			}
			if ((this.cat_id.id !== this.prevCatId.id) || !this.cat_id.length) {
				this.getBrandsOfCategory(this.cat_id.map(x => x.id || x));
			}
			//this.getBrandsOfCategory(this.cat_id.map(x => x.id))
			this.prevCatId = this.cat_id;
		})

		this.filterService.prodCategory.subscribe(val => {
			this.prodCat = val;
		})

		this.isLoading = true;

		let sub = this.route.queryParams.subscribe(val => {
			this.userService.getBrands(this.authenticationService.getCurrentUserToken())
				.subscribe((data: Array<any>) => {
					this.brands = data.map(e => e.supplier);
					this.brands_initial = data.map(e => e.supplier);
					this.isLoading = false;
					if (val) {
						let query: FilterParams = { cat_id: [], brand_id: [] };
						if (val['cat_id'] && val['cat_id'].length) {
							this.cat = val['cat_id'];
							this.cat_id = [];
							this.cat_id.push(+val['cat_id'])
							this.cat_id.map(x => {
								query.cat_id.push(x.id || x)
							});

						}
						if(!(val['brand_id'] && val['brand_id'].length)) {
							this.filterService.setFilters(query);
						}
						if (val['brand_id'] && val['brand_id'].length) {
							this.userService.getBrands(this.authenticationService.getCurrentUserToken(), this.cat_id.map(x => x.id || x))
								.subscribe((data: Array<any>) => {
									let brandQuery = {};
									brandQuery = {...val['brand_id']};
									this.brand_id = [];
									this.brands = data.map(e => e.supplier);
									this.brands_initial = data.map(e => e.supplier);
									Object.keys(brandQuery).forEach(item => {
										let brandObj = this.brands_initial.find(x => x.id == brandQuery[item]);
										this.brand_id.push({
											type: 'brand_id',
											id: brandObj.id,
											name: brandObj.company_name
										})
										this.brands = this.brands.filter(x => x.id !== brandObj.id);
									})
									this.brand_id.map(x => {
										query.brand_id.push(x.id || x)
									});
									this.filterService.setFilters(query);
								})
						}
					}
				})
			//sub.unsubscribe();
		});
		sub.unsubscribe();
	}

	getBrandsOfCategory(categories: string) {
		this.userService.getBrands(this.authenticationService.getCurrentUserToken(), categories)
			.subscribe((data: Array<any>) => {
				this.brand_id = [];
				this.brands = data.map(e => e.supplier);
				this.brands_initial = data.map(e => e.supplier);

			})
	}

	clearAll() {
		this.brand_id = [];
		this.color_id = [];
		this.brands = this.brands_initial;
		this.colors = this.colors_initial;

		let query = '';
		if (this.cat_id.length) {
			this.cat_id.map(x => query += '&cat_id=' + x.id)
		}
	}


	showAllProducts() {
		this.filterService.setCategory(null);
		this.filterService.setFilters({});
		this.brand_id = [];
		this.cat_id = [];
		this.color_id = [];
		this.brands = this.brands_initial;
		this.colors = this.colors_initial;
		this.categories = this.categories_initial;
		this.cat = null;
		this.getBrandsOfCategory('');
		this.isOpened = false;
	}

	clearFilter(type: string) {
		this[type] = [];
		switch (type) {
			case 'brand_id':
				this.brands = this.brands_initial;
			case 'color_id':
				this.colors = this.colors_initial;
		}
	}

	selectCategory(id: number) {
		this.cat = id;
		if (this.router.url === '/shop/catalog/main') {
			this.router.navigate(['/shop/catalog']);
		}
	}

	removeFilter(item) {
		this[item.type] = this[item.type].filter(x => x.id !== item.id);

		switch (item.type) {
			case 'brand_id': {
				this.brands = this.brands_initial.filter(x => {
					if (!this.brand_id.find(e => e.id === x.id)) return x;
				})
				break;
			}
			case 'cat_id': {
				this.categories = this.categories_initial.filter(x => {
					if (!this.cat_id.find(e => e.id === x.id)) return x;
				})
				this.getBrandsOfCategory(this.cat_id.map(x => x.id));
			}
			case 'color_id': {
				this.colors = this.colors_initial.filter(x => {
					if (!this.color_id.find(e => e.id === x.id)) return x;
				})
			}
		}

		var query: FilterParams = { cat_id: [], brand_id: [] };
		if (this.brand_id.length) {
			this.brand_id.map(x => {
				query.brand_id.push(x.id)
			})
		}
		if (this.cat_id.length) {
			this.cat_id.map(x => {
				query.cat_id.push(x.id || x)
			})
		}
		if (this.color_id.length) {
			query['color_id'] = [];
			this.color_id.map(x => query['color_id'].push(x.id));
		}
		this.filterService.setFilters(query);
	}

	selectFilter(type: string, id: number, name: string) {
		let obj = {
			type: type,
			id: id,
			name: name
		}
		switch (type) {
			case 'brand_id': {
				this.brand_id.push(obj);
				this.brands = this.brands.filter(x => x.id !== id);
				break;
			}
			case 'color_id': {
				this.color_id.push(obj);
				this.colors = this.colors.filter(x => x.id !== id);
				break;
			}
			case 'cat_id': {
				this.cat_id[0] = obj;
				this.cat = id;
				this.brand_id = [];
				//this.categories = this.categories.filter(x => x.id !== id);
				this.getBrandsOfCategory(this.cat_id.map(x => x.id));
				this.isOpened = false;
			}
		}

		var query: FilterParams = { cat_id: [], brand_id: [] };
		if (this.brand_id.length) {
			this.brand_id.map(x => {
				query.brand_id.push(x.id)
			})
		}
		if (this.cat_id.length) {
			this.cat_id.map(x => {
				query.cat_id.push(x.id || x)
			})
		}
		if (this.color_id.length) {
			query['color_id'] = [];
			this.color_id.map(x => query['color_id'].push(x.id));
		}
		this.filterService.setFilters(query);
		if (this.router.url === '/shop/catalog/main' || this.router.url.indexOf('/shop/product/') > -1) {
			this.router.navigate(['/shop/catalog'], { queryParams: query });
		}
	}
}
