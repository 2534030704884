import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR, AbstractControl, FormControl } from '@angular/forms';
import { Component, Input, SkipSelf, Host, Optional, forwardRef } from '@angular/core';
// import { ViewEncapsulation } from '@angular/compiler/src/core';

@Component({
  selector: 'date-picker',
  templateUrl: 'date-picker.component.html',
  styleUrls: ['date-picker.component.sass'],
  providers: [
    {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => DatepickerComponent),
        multi: true
    }
]
//   encapsulation: ViewEncapsulation.Emulated
})
export class DatepickerComponent implements ControlValueAccessor {
  inputText: any;
  @Input() formControlName: string;
  @Input() class = 'form-control'
  @Input() label = '';
  @Input() placeholder = '';
  today = new Date();
  tomorrow = new Date();
  date: any;

    control: AbstractControl;

    constructor (
        @Optional() @Host() @SkipSelf()
        private controlContainer: ControlContainer
    ) {}

    writeValue(value: any) {
        if (value !== undefined) {
            this.inputText = value;
        }
    }

    onInputChange(){
        this.propagateChange(this.date.value.toISOString())
    }

    propagateChange = (_: any) => {};

    registerOnChange(fn) {
        this.propagateChange = fn;
    }

    registerOnTouched() {}
    ngOnInit(): void {
        this.tomorrow = new Date(this.tomorrow.setDate(this.today.getDate() + 1));
        this.tomorrow.setHours(0,0,0,0);
        this.date  = new FormControl();
        this.inputText = this.date.value;
        if (this.controlContainer) {
            if (this.formControlName) {
                this.control = this.controlContainer.control.get(this.formControlName);
                this.control.valueChanges.subscribe((x) => {
                    if(!x) {
                        this.control.setValue(this.date.value);
                        this.propagateChange(this.date.value);
                        this.date.setValue(new Date(new Date(new Date(new Date().setFullYear(new Date().getFullYear() + 1)).setDate(new Date().getDate() + 1)).setHours(0,0,0,0)))
                    }
                })
                if(this.control.value) {
                    this.date.setValue(new Date(Date.parse(this.control.value)));
                } else {
                    this.control.setValue(this.date.value);
                    this.propagateChange(this.date.value);
                }
            } else {
                console.warn('Missing FormControlName directive from host element of the component');
            }
        } else {
            console.warn('Can\'t find parent FormGroup directive');
        }
    }

    setDisabledState(isDisabled: boolean): void {}
}