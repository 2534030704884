import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-envelope',
	templateUrl: './envelope.component.html',
	styleUrls: ['./envelope.component.scss']
})
export class EnvelopeComponent implements OnInit {
	constructor() { }

	ngOnInit(): void { }
}
