import { TranslationService } from './../../_services/translation';
import { ModalService } from './../../_services/modal';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.sass']
})
export class HeaderComponent implements OnInit {
    constructor(
        private router: Router,
        private modalService: ModalService,
        private translationService: TranslationService) { }
    sections: any = ["start", "about", "partners", "reviews", "how-it-works"];
    currentLanguage: string = 'en';
    isMenuOpened: boolean = false;
    //currentSection: number = 1;
    ngOnInit(): void {
        this.translationService.currentLanguage.subscribe(value => {
            this.currentLanguage = value;
        })
    }
    
    changeLanguage(lang_code: string) {
        this.translationService.changeLanguage(lang_code);
        this.currentLanguage = this.translationService.getLanguage();
    }
}
