import { DomSanitizer } from '@angular/platform-browser';
import { PipeTransform, Pipe, Sanitizer, SecurityContext } from '@angular/core';
@Pipe({
    name: 'sup',
    pure: false
})
export class SupPipe implements PipeTransform {
    constructor(
        private sanitizer: DomSanitizer
    ){}
    transform(value: string, regex): any {
        return this.sanitizer.bypassSecurityTrustHtml(this.replace(value, regex));
    }

    replace(str, regex) {
        if(str) return str.replace(new RegExp(/\(([^)]+)\)/gi), '<sup style="line-height: 0; position: relative; top: -5px; font-size: 60%">$1</sup>');
        else return ''
    }

    sanitize(str) {
        return this.sanitizer.sanitize(SecurityContext.HTML, str);
    }
}

