import {Injectable} from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';

@Injectable()
export class FilterService {
    constructor() { }
    private filters = new BehaviorSubject(null);
    currentFilters = this.filters.asObservable();
    private category = new BehaviorSubject(null);
    currentCategory = this.category.asObservable();
    private loading = new BehaviorSubject(false);
    isLoading = this.loading.asObservable();
    private productCategory = new BehaviorSubject(null);
    prodCategory = this.productCategory.asObservable();

    setFilters(query: Object) {
        this.filters.next(query);
    }

    getFilters() : Object {
        return this.filters.value;
    }

    setCategory(id) {
        this.category.next(id);
    }

    setLoading() {
        this.loading.next(true);
    }

    finishLoading() {
        this.loading.next(false);
    }

    setProductCategory(id) {
        this.productCategory.next(id);
    }

}
