import { isPlatformBrowser } from '@angular/common';
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../_services/authentification';

@Injectable()
export class InfluencerGuard implements CanActivate {

	constructor(
		private router: Router,
		private authenticationService: AuthenticationService,
		@Inject(PLATFORM_ID) private platformId: Object
	) { }

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		if (isPlatformBrowser(this.platformId)) {
			let user = JSON.parse(localStorage.getItem('SCORER_CURRENT_USER'));
			//console.log(user);
			if (user) {
				if (user.scope[0].indexOf('influencer') > -1) {
					let regSteps;
					regSteps = Object.assign({}, user.user['registration_steps_completeness']);
					if (!regSteps.is_personal_info_filled) {
						this.router.navigate(['registration'])
					}
					if ((!regSteps.is_photo_uploaded) && (!regSteps.is_instagram_linked)) {
						this.router.navigate(['verification'])
					}
					else if (!regSteps.is_email_confirmed) {
						if (this.authenticationService.getCurrentUser()['user']['email'])
							this.router.navigate(['confirmation'], { queryParams: { register: "by-email" } })
					}
					else if ((!regSteps.is_photo_verified) && (!regSteps.is_instagram_approved)) {
						
						if (user.user['influencer']['instagram_moderation_status'] != null){
							if (user.user['influencer']['instagram_moderation_status'] == 'waiting'){
								this.router.navigate(['account/waiting']);
							}
							else if (user.user['influencer']['instagram_moderation_status'] == 'rejected'){
								this.router.navigate(['account/rejected'])
							}
						}
						else{
							if (user.user['influencer']['photo_moderation_status'] == 'waiting'){
								this.router.navigate(['account/waiting']);
							}
							else if (user.user['influencer']['photo_moderation_status'] == 'rejected'){
								this.router.navigate(['account/rejected'])
							}
						}
					}
					else return true;
				} else {
					this.router.navigate(['brand/categories']);
					return false;
				}
			}


			else {
				this.router.navigate(['login']);
				return false;
			}
		}
		else return false
	}
}