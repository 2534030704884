import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Injectable()
export class CookieService {

	setCookie(name: string, value: string, days: number) {
		var expires = "";
		if (days) {
			var date = new Date();
			date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
			expires = "; expires=" + date.toUTCString();
		}
		document.cookie = name + "=" + (value || "") + expires + "; path=/";
	}

	getCookie(name: string) {
		var cookiestring=RegExp(""+name+"[^;]+").exec(document.cookie);
  	// Return everything after the equal sign, or an empty string if the cookie name not found
  	return decodeURIComponent(!!cookiestring ? cookiestring.toString().replace(/^[^=]+./,"") : "");
	}

	eraseCookie(name: string) {
		document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`
	}

}