import {Injectable} from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class DataService {
    constructor() { }
    data: any = new Subject<any>();
    setData(newValue) {
        this.data.next(newValue);
    }
    getData() : Observable<any> {
        return this.data.asObservable();
    }
}
