import { TranslationService } from './../../_services/translation';
import { Component, OnInit, Input } from '@angular/core';
import { VgAPI } from 'videogular2/core';

@Component({
    selector: 'app-player',
    templateUrl: './player.component.html',
    styleUrls: ['./player.component.sass']
})
export class PlayerComponent implements OnInit {
    constructor(
        private translationService: TranslationService
    ) { }
    @Input() url = '';
    api: VgAPI;
    currentLanguage: string = 'en';

    ngOnInit(): void {

        this.translationService.currentLanguage.subscribe(value => {
            this.currentLanguage = value;
        })
        
        var video1 = document.getElementById('#explanationVid');

        function videoPausePlayHandler(e) {
            if (e.type == 'playing') {
            //add controls
            video1.setAttribute("controls","controls");
            } else if (e.type == 'pause') {
        //remove controls
        video1.removeAttribute("controls");
            }
        }

        //Add event listeners
        if(video1) {
            video1.addEventListener('playing', videoPausePlayHandler, false);
            video1.addEventListener('pause', videoPausePlayHandler, false);
        }
     }

     onPlayerReady(api: VgAPI) {
        this.api = api;
    }

    play() {
        this.api.play();
    }

}
