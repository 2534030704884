import { FormService } from './../../_services/form';
import { CustomValidator } from './../../_services/validators';
import { Validators, FormControl, FormGroup } from '@angular/forms';
import { AuthenticationService } from './../../_services/authentification';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { UserService } from '../../_services/user';

@Component({
    selector: 'app-login-supplier',
    templateUrl: './login-supplier.component.html',
    styleUrls: ['./login-supplier.component.sass']
})
export class LoginSupplierComponent implements OnInit {
    constructor(
        private authenticationService: AuthenticationService,
        private userService: UserService,
        private router: Router,
        private route: ActivatedRoute,
        private formService: FormService
    ) { 
    }

    isLoading: boolean = false;
    isEmailSent: boolean = false;
    hasError: boolean;
    error: string;
    passRecovery: boolean = false;

    form = new FormGroup({
        email: new FormControl('', [Validators.required, CustomValidator.emailValidator]),
        password: new FormControl('', [Validators.required])
    })

    ngOnInit(): void { 
    }

    login() {
        this.isLoading = true;
        let email = this.form.controls.email.value;
        let pass = this.form.controls.password.value;
        if(email && pass) {
            this.form.disable();
            this.authenticationService.login(email, pass)
                .subscribe(
                    data => {
                        this.isLoading = false;
                        if(!data['user']['supplier']) {
                            this.router.navigate(['shop/catalog/main'])
                        }
                        else if(data['user']['supplier'] && !data['user']['supplier']['is_information_confirmed']) 
                            this.router.navigate(['/brand/confirm'])
                        else 
                            this.router.navigate(['/brand/categories'])
                    },
                    error => {
                        this.isLoading = false;
                        this.form.enable();
                        this.hasError = true;
                        this.error = error['error']['detail'];
                    }
                )
            }
    }

    requestPasswordChange() {
        this.isLoading = true;
        this.form.controls.password.setErrors(null);
        this.form.controls.password.clearValidators();
        if(this.form.valid) {
            this.form.disable();
            let email = this.form.controls.email.value;
            this.authenticationService.requestPassword(email)
                .subscribe(
                    data => {
                        this.isLoading = false;
                        this.isEmailSent = true;
                        this.form.enable();
                    },
                    error => {
                        this.isLoading = false;
                        this.hasError = true;
                        if(error['error']['email']) {
                            this.error = error['error']['email'][0];
                        }
                        this.form.enable();
                    }
                )
        }
        else {
            this.isLoading = false;
            this.formService.markTouched(this.form);

        }
    }

    goToLogin() {
        this.passRecovery = false;
        this.hasError = false;
        this.isEmailSent = false;
        this.form.controls.password.setValidators([Validators.required])
        this.form.reset();
    }
}
