// import { environment } from './../../environments/environment';
import { Router } from '@angular/router';
import { AuthenticationService } from './../_services/authentification';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment'

@Injectable()
export class ApiPathInterceptor implements HttpInterceptor {
	url_prefix = 'api/v1/';
	constructor(
		private authenticationService: AuthenticationService,
		private router: Router) { }

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

		let strURL = request.url;

		if (strURL.includes('https://api.instagram.com')){
			request = request.clone({
				url: request.url
			});
		}
		else if(strURL.includes('https://graph.instagram.com')){
			request = request.clone({
				url: request.url
			});
		}
		else if(strURL.includes('https://www.instagram.com')){
			request = request.clone({
				url: request.url
			});
		}
		else
		{
			request = request.clone({
				url: environment.apiUrl() + this.url_prefix + request.url
			});
		}

		return next.handle(request).pipe(
			tap(
				event => { },
				error => {
					if (error.status === 401 && error.error.code === 'authentication_failed') {
						this.authenticationService.logout();
						this.router.navigate(['/'])
					}
				}
			)
		);
	}
}