import { ContentService } from './../../_services/content';
import { TranslationService } from './../../_services/translation';
import { UserService } from './../../_services/user';
import { CustomValidator } from './../../_services/validators';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ModalService } from './../../_services/modal';
import { AuthenticationService } from 'src/app/_services/authentification';

@Component({
    selector: 'footer-home',
    templateUrl: './footer-home.component.html',
    styleUrls: ['./footer-home.component.sass']
})
export class FooterHomeComponent implements OnInit {
    germanCancellation: string = ''
    englishCancellation: string = '';

    constructor(
        private modalService: ModalService,
        public router: Router,
        private userService: UserService,
        private contentService: ContentService,
        private authenticationService: AuthenticationService,
        private translationService: TranslationService
    ) { }

    currentLanguage: string = 'en';

    ngOnInit(): void {
        this.translationService.currentLanguage.subscribe(value => {
            this.currentLanguage = value;
        });

        this.getCancellation();
    }

    form = new FormGroup({
        email: new FormControl('', [Validators.required, Validators.maxLength(254), CustomValidator.isBlank, CustomValidator.emailValidator])
    })

    isSubscribed: boolean = false;

    getCancellation(){
        this.contentService.getCancellation(this.authenticationService.getCurrentUserToken()).subscribe(data => {
            if (data[0]){
                this.setCancellation(data[0]['language'], data[0]['content']);
            }
            if (data[1]){
                this.setCancellation(data[1]['language'], data[1]['content']);
            }
        })
    }

    setCancellation(language: string, content: string){
        if (language == 'german'){
            this.germanCancellation = content;
        }
        if (language == 'english'){
            this.englishCancellation = content;
        }
    }

    subscribe() {
        let email = this.form.controls.email.value;
        this.userService.subscribeToNewsletter(email).subscribe(data => {
            this.isSubscribed = true;
            this.form.reset();
            this.form.setErrors(null);
        }, error => {
            this.form.controls.email.setErrors({exists: true})
        })
    }

    openModal(id) {
        this.modalService.open(id);
    }

    closeModal(id) {
        const topElement = document.querySelector('#' + id + ' h1');
        if(topElement) 
            topElement.scrollIntoView();
        if(id === 'modal-subscription')
            this.isSubscribed = false;
        this.modalService.close(id);
    }

    clickOutsideModal(e: Event, id: string) {
        var target = $(e.target);
		if (!target.closest('.modal-body').length) {
			this.closeModal(id);
		}
    }

}
