import { TranslationService } from './../../_services/translation';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.sass']
})
export class TermsComponent implements OnInit {

  constructor(
    private translationService: TranslationService
  ) { }

  currentLanguage: string = 'de';
  ngOnInit() {
    this.translationService.currentLanguage.subscribe(value => {
      this.currentLanguage = value;
    })
  }
  changeLanguage(lang_code: string) {
		this.translationService.changeLanguage(lang_code);
		this.currentLanguage = this.translationService.getLanguage();
	}

}
