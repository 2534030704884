import { TranslationService } from './translation';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {UserAuthInfo} from '../_models/user';


@Injectable()
export class RegistrationService {
    constructor(
        private http: HttpClient,
        private translationService: TranslationService
        ) { }
    
    registerWithEmail(obj) {
        return this.http.post('registration/fill-personal-info-with-email/', obj, {
            headers: new HttpHeaders().set('Accept-Language', this.translationService.getLanguage())
        });
    }

    registerWithSocial(data_dict, token) {
        return this.http.post('registration/fill-personal-info/', data_dict, {
            headers: new HttpHeaders().set('Authorization', 'JWT ' + token).append('Accept-Language', this.translationService.getLanguage())
        });
    }

    registerWithInstagram(obj) {
        return this.http.post('registration/fill-personal-info-instagram/', obj, {
            headers: new HttpHeaders().set('Accept-Language', this.translationService.getLanguage())
        });
    }

    uploadVerificationPhoto(obj, token) {
        return this.http.post('registration/upload-photo/', obj, {
            headers: new HttpHeaders().set('Authorization', 'JWT ' + token)
        });
    }

    instagramVerification(obj, token){
        return this.http.post('registration/instagram/', obj, {
            headers: new HttpHeaders().set('Authorization', 'JWT ' + token)
        });
    }
    
    requestEmailConfirmation(email: string, token) {
        return this.http.post('registration/request-email-confirmation/', {email: email}, {
            headers: new HttpHeaders().set('Authorization', 'JWT ' + token)
        })
    }

    confirmEmail(token: string) {
        return this.http.post('registration/confirm-email/', {token: token});
    }

    getUserNextStep(userAuthInfo: UserAuthInfo) {
        function body() {
            if (userAuthInfo == null) {
                return '/';
            }
            if (userAuthInfo.scope.includes('influencer:edit_profile')) {
                return '/shop/catalog/main';
            } else { // if (userAuthInfo.scope.includes('influencer:register')) {
                let rsc = userAuthInfo.user.registration_steps_completeness;

                if (!rsc.is_personal_info_filled) {
                    return '/registration';
                }

                if ((!rsc.is_photo_uploaded) && (!rsc.is_instagram_linked)) {
                    return '/verification';
                }

                if (!rsc.is_email_confirmed) {
                    return '/confirmation';
                }
            }
            throw Error('Unhandled case');
        }
        let res = body();
        return res;
    }
}
