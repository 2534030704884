import { ValidatorFn, AbstractControl } from '@angular/forms';
import { NgControl } from '@angular/forms';
export class CustomValidator {

    // Validates URL
    // static urlValidator(url): any {
    //     if (url.pristine) {
    //         return null;
    //     }
    //     if (url.value === '') {
    //         return null;
    //     }
    //     const URL_REGEXP = /^((http?|ftp)s?:\/\/)?([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
    //     url.markAsTouched();
    //     if (URL_REGEXP.test(url.value)) {
    //         return null;
    //     }
    //     return {
    //         invalidUrl: true
    //     };
    // }

    static birthDateValidator(date) {
        var today = new Date();
        var birthDate = new Date(date['value']);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        if (age < 18) {
            return {
                invalidAge: true
            }
        }
    }

    static true(value) {
        value.markAsTouched();
        if (!value.value) {
            return {
                false: true
            }
        } else return null;
    }
    static images(images) {
        if (images.value && !images.pristine) {
            if (!images.value.length) {
                return { noImg: true }
            }
            return null;
        }
    }
    static emailValidator(email): any {
        email.markAsTouched();
        if (/\S+@\S+\.\S+/.test(email.value)) {
            return null;
        }
        else return {
            invalidEmail: true
        }
    }

    static optionalEmail(email): any {
        email.markAsTouched();
        if (/\S+@\S+\.\S+/.test(email.value) || !email.value) {
            return null;
        }
        else return {
            invalidEmail: true
        }
    }
    static tags(x): any {
        if (x.pristine) {
            return null;
        }
        let arr = x.value.split(',');
        if (arr.length < 6) {
            return null;
        }
        return {
            invalidTags: true
        }
    }

    static positiveValue(x) {
        if (x.value < 0) {
            return {
                negative: true
            }
        }
        return null;
    }

    //validate select
    static selectMiss(select) {
        if (select.value.length) {
            return null;
        }
        if (!select.value.length) {
            return {
                invalidSelect: true
            }
        }
    }

    static discountRange(x) {
        if (x.value) {
            let value = Number(x.value.toString().replace('/,/g', '.'))
            if (value < 0 || value > 100) {
                return {
                    invalidRange: true
                }
            }
        }
        return null;
    }

    static discount(language): ValidatorFn {
        return (control: AbstractControl) => {
            if (control.value) {
                let value = control.value.toString();
                let after = '';

                if (language === 'en') {
                    if (value && value.indexOf('.') > -1) {
                        value = control.value.toString().substr(0, control.value.toString().indexOf('.'));
                        after = control.value.toString().substr(control.value.toString().indexOf('.') + 1);
                    }
                } else {
                    if (value && value.indexOf(',') > -1) {
                        value = control.value.toString().substr(0, control.value.toString().indexOf(','));
                        after = control.value.toString().substr(control.value.toString().indexOf(',') + 1);
                    }
                }

                if (after.length > 2) {
                    return {
                        invalidDiscount: true
                    }
                }
            }
            return null
        }

    }
    
    static integer(x) {
        if(x.value) {
            if(!/^\d+$/.test(x.value)) {
                return {
                    notInteger: true
                }
            } else return null;
        }
        return null;
    }

    static price(language): ValidatorFn {
        return (control: AbstractControl) => {
            if (control.value) {
                let value = control.value.toString();
                let after = '';

                if (language === 'en') {
                    if (value && value.indexOf('.') > -1) {
                        value = control.value.toString().substr(0, control.value.toString().indexOf('.')).replace(/[,]/g, '');
                        after = control.value.toString().substr(control.value.toString().indexOf('.') + 1);
                    }
                } else {
                    if (value && value.indexOf(',') > -1) {
                        value = control.value.toString().substr(0, control.value.toString().indexOf(',')).replace(/[.]/g, '');
                        after = control.value.toString().substr(control.value.toString().indexOf(',') + 1);
                    }
                }

                if (value.length > 7 || after.length > 2) {
                    return {
                        invalidPrice: true
                    }
                }
            }
            return null
        }
    }

    //validates whether entered url is fb or inst link
    static socialLinkValidator(url) {
        if (!/(?:https?:\/\/)?(?:www\.)?facebook\.com\/.(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-\.]*)/.test(url.value) &&
            !/(?:https?:\/\/)?(?:www\.)?instagram\.com\/[a-z\d-_]{1,255}\s*\/?$/i.test(url.value)) {
            return {
                invalidSocial: true
            }
        }
    }

    static socialPostLinkValidator(url) {
        if (!/(?:https?:\/\/)?(?:www\.)?facebook\.com\/.(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-\.]*)/.test(url.value) &&
            !/(?:https?:\/\/)?(?:www\.)?instagram\.com\/p\/[a-z\d-_]{1,255}\s*\/?.*$/i.test(url.value)) {
            return {
                invalidSocialPost: true
            }
        }
    }

    static isBlank(x) {
        if ((x.value && x.value.length === 0) || (x.value && !x.value.toString().trim())) {
            return {
                blank: true
            }
        }
        return null;
    }

    static urlValidator(url) {
        if (!/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i.test(url.value)) {
            return {
                invalidUrl: true
            }
        }
    }

    static facebook(url) {
        if (!/^(?:https?:\/\/)?(?:www\.)?facebook\.com\/.(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-\.]*)/.test(url.value)) {
            return {
                invalidFacebook: true
            }
        }
    }

    // Validates passwords
    static matchPassword(group): any {
        const password = group.controls.password;
        const confirm = group.controls.password_confirm;
        if (password.pristine || confirm.pristine) {
            return null;
        }
        group.markAsTouched();
        if (password.value !== confirm.value) {
            return {
                invalidPassword: true
            }
        }
    }

    static passwordSpaces(pass) {
        if (pass.value && !/^[^\s]+(\s+[^\s]+)*$/.test(pass.value)) {
            return {
                spaces: true
            }
        } else return null;
    }
    // Validates numbers
    static numberValidator(number): any {
        if (number.pristine) {
            return null;
        }
        const NUMBER_REGEXP = /^-?[\d.]+(?:e-?\d+)?$/;
        number.markAsTouched();
        if (NUMBER_REGEXP.test(number.value)) {
            return null;
        }
        return {
            invalidNumber: true
        };
    }
    // Date
    static date(date: any) {
        if (date.pristine) {
            return null;
        }
        const RegExp = /[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1]) (2[0-3]|[01][0-9]):[0-5][0-9]/;
        date.markAsTouched();
        if (RegExp.test(date.value)) {
            return null;
        }
        return {
            invalidData: true
        };
    }
    // Validates US SSN
    static ssnValidator(ssn): any {
        if (ssn.pristine) {
            return null;
        }
        const SSN_REGEXP = /^(?!219-09-9999|078-05-1120)(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/;
        ssn.markAsTouched();
        if (SSN_REGEXP.test(ssn.value)) {
            return null;
        }
        return {
            invalidSsn: true
        };
    }
    // Validates zip codes
    static zipCodeValidator(zip): any {
        if (zip.pristine) {
            return null;
        }
        const ZIP_REGEXP = /^[0-9]{5}(?:-[0-9]{4})?$/;
        zip.markAsTouched();
        if (ZIP_REGEXP.test(zip.value)) {
            return null;
        }
        return {
            invalidZip: true
        };
    }
    // Phone number
    static phoneNumber(value): any {
        if (value.pristine) {
            return null;
        }
        const ZIP_REGEXP = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
        value.markAsTouched();
        if (ZIP_REGEXP.test(value.value)) {
            return null;
        }
        return {
            invalidPhone: true
        };
    }

    static stockValidator(x) : any {
        const size = x.controls.size;
        const size_items = x.controls.size_items;
        if(x.value) {
            if(size_items.value && size_items.value.some(e => e.stock > 10000)) {
                return {
                    stockError: true
                }
            } else return null
        }
    }
}