import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-sort',
  templateUrl: './select-sort.component.html',
  styleUrls: ['./select-sort.component.sass']
})
export class SelectSortComponent implements OnInit {
  @Input() options: any;
  @Input() defaultSort: any;
  @Output() private valueChange = new EventEmitter();
  constructor() { }

  isFilterMenuOpened: boolean = false;
  sort: string = '';

  ngOnInit() {
    $(window).on('click', (e) => {
      if(!$(e.target).closest('.sort-menu').length) {
        this.isFilterMenuOpened = false;
      }
    })
    if (this.defaultSort) {
      this.sort = this.defaultSort;
    }
  }

  sortProducts(option, e?: Event) {
    this.sort = option;
    this.isFilterMenuOpened = false;
    this.valueChange.emit(option.value);
  }

  reset() {
    this.sort = null;
    this.isFilterMenuOpened = false;
    this.valueChange.emit('');
  }

  setSort(value: string) {
    this.sort = value;
  }
}
