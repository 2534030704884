import { environment } from './../../../environments/environment.prod';
import {LoginOpt, SocialUser} from 'angularx-social-login';
import {BehaviorSubject} from 'rxjs';

export class InstagramLoginProvider {
    static readonly PROVIDER_ID: string = 'InstagramProviderID';
    // copy-code cause *js cannot import BaseLoginProvider from .d.ts file
    // import {BaseLoginProvider} from 'angularx-social-login/src/entities/base-login-provider';
    // , and that class isn't exportable.
    protected _readyState: BehaviorSubject<boolean> = new BehaviorSubject(false);

    private static getHashParameterByName(name, url) {
        if (!url) {
            url = window.location.href;
        }
        name = name.replace(/[\[\]]/g, '\\$&');
        let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) {
            return null;
        }
        if (!results[2]) {
            return '';
        }

        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

    protected onReady(): Promise<void> {
        return new Promise((resolve, reject) => {
            this._readyState.subscribe((isReady) => {
                if (isReady) {
                    resolve();
                }
            });
        });
    }

    constructor(
        private clientId: string,
        private secret: string,
        public readonly redirectUri: string,
    ) {}

    initialize(): Promise<void> {
        return new Promise((resolve, reject) => {
            // no lazy initialization is needed
            this._readyState.next(true);
            resolve();
        });
    }

    getLoginStatus(): Promise<SocialUser> {
        return new Promise((resolve, reject) => {
            this.onReady().then(() => {
                resolve(new SocialUser());
            });
        });
    }

    signIn(opt?: LoginOpt): Promise<SocialUser> {
        let clientId = this.clientId;
        let redirectUri = this.redirectUri;
        if (redirectUri.startsWith('/')) {
            redirectUri = window.location.origin + redirectUri;
            if(redirectUri.indexOf('#') > -1) {
                redirectUri = redirectUri.split('#')[0];
            }
        }
        
        return new Promise((resolve, reject) => {
            this.onReady().then(() => {
                // the pop-up window size, change if you want
                const popupWidth = 700,
                    popupHeight = 500,
                    popupLeft = (window.screen.width - popupWidth) / 2,
                    popupTop = (window.screen.height - popupHeight) / 2;

                // the url needs to point to instagram_auth.php
                const instagra_auth_url = 'https://instagram.com/oauth/authorize/?app_id=' + clientId +
                            '&redirect_uri=' + redirectUri +
                            '&scope=user_profile,user_media' +
                            '&response_type=code';
                
                const popup = window.open(instagra_auth_url, '_blank', 'width=' + popupWidth +
                    ',height=' + popupHeight +
                    ',left=' + popupLeft +
                    ',top=' + popupTop + ''
                );
                //popup.onload = function() {
                    // open authorize url in pop-up
                    if (window.location.hash.length === 0) {
                        popup.open(instagra_auth_url, '_self');
                    } else {
                        popup.open(instagra_auth_url, '_self');
                    }

                    // an interval runs to get the access token from the pop-up
                    const interval = setInterval(function() {
                        if (popup == null || popup.window == null || popup.location == null || popup.location.href == null) {
                            clearInterval(interval);
                            reject();
                        }
                        try {
                            // check if hash exists
                            let code =  InstagramLoginProvider.getHashParameterByName('code', popup.location.href);
                            if (code) {
                                clearInterval(interval);
                                let user: SocialUser = new SocialUser();
                                user.idToken = code;
                                user.name = 'Instagram user';
                                user['redirectUri'] = redirectUri;
                                
                                popup.close();
                                resolve(user);
                            }
                        } catch (evt) {
                            clearInterval(interval);
                            try {
                                popup.close();
                            } catch (e) {
                            }
                            reject();
                        }
                    }, 50);
               // };
            });
        });
    }

    signOut(): Promise<any> {
        return new Promise((resolve, reject) => {
            throw new Error('Not implemented');
        });
    }
}
