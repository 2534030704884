import { DomSanitizer } from '@angular/platform-browser';
import { PipeTransform, Pipe, Sanitizer, SecurityContext } from '@angular/core';
@Pipe({
    name: 'underline',
    pure: false
})
export class UnderlinePipe implements PipeTransform {
    constructor(
        private sanitizer: DomSanitizer
    ){}
    transform(value: string, regex): any {
        return this.sanitizer.bypassSecurityTrustHtml(this.replace(value, regex));
    }

    replace(str, regex) {
        if(str) return str.replace(new RegExp(/\(([^)]+)\)/gi), '<span style="text-decoration: underline">$1</span>');
        else return ''
    }

    sanitize(str) {
        return this.sanitizer.sanitize(SecurityContext.HTML, str);
    }
}

