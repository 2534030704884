import { TranslationService } from './../../../_services/translation';
import { Component, OnInit, Input, forwardRef, Optional, Host, SkipSelf, EventEmitter, Output, ElementRef } from '@angular/core';
import {
    AbstractControl,
    ControlContainer,
    ControlValueAccessor,
    FormArray,
    FormControl,
    FormGroup,
    NG_VALUE_ACCESSOR
} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import { pureObjectDef } from '@angular/core/src/view';

@Component({
    selector: 'app-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.sass'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SelectComponent),
            multi: true
        }
    ]
})
export class SelectComponent implements ControlValueAccessor, OnInit {
    inputText: any = {};
    inputSelect: any;
    isExpanded: boolean = false;
    element: any;
    @Input() label = '';
    @Input() options: any = []; 
    @Input() dis = false;
    @Input() type = "";
    @Input() formControlName: string;
    @Input() placeholder: any = {}
    @Input() color = false;
    @Input('group')
    public form_group: FormGroup;
    @Output() private valueChange = new EventEmitter();
    control: AbstractControl;

    currentLanguage: string = 'en';

    constructor(@Optional() @Host() @SkipSelf()
                private controlContainer: ControlContainer,
                private router: Router,
                private el: ElementRef,
                private route: ActivatedRoute,
                private translationService: TranslationService) {
        this.element = $(el.nativeElement);
    }

    static createFormGroup(initial_value?: any): FormControl {

            return new FormControl( {});

    }

    showCheckboxes(event) {
        var checkboxes = $("#checkboxes-"+this.formControlName);
        if (!this.isExpanded && !this.dis) {
            this.isExpanded = true;
        } else {
          this.isExpanded = false;
        }

      }

    highlight(field) {
        field.className = '';
    }
    ngOnInit(): void {
        this.translationService.currentLanguage.subscribe(value => {
            this.currentLanguage = value;
        })
        if(this.type == "size") {
            this.options.map(e => {
                if (e.stock === 0) {
                    e.disabled = true
                }
            })
        }
        this.clickOutside();
        if (this.controlContainer) {
            if (this.formControlName) {
                this.control = this.controlContainer.control.get(this.formControlName);
                if(this.options) {
                    if(this.type == 'size') {
                        this.inputSelect = this.options.find(obj => obj.size.id == this.control.value).size;
                    }
                    else this.inputSelect = this.options.find(obj => obj.id == this.control.value);
                }
                this.control.valueChanges.subscribe(x => {
                    if (typeof(this.control.value) === 'object') {
                        if (x !== undefined) {
                            let find = this.options.find(z => z.id === x);
                            this.control.setValue(find);
                        }
                    }
                });
            } else {
                console.warn('Missing FormControlName directive from host element of the component');
            }


        } else {
            console.warn('Can\'t find parent FormGroup directive');
        }
        if (this.form_group) {
            this.control = this.form_group;
        }
    }

    setDisabledState(isDisabled: boolean): void {
    }

    writeValue(value: any) {
        this.inputText = value;
        if(this.options) {
            if(this.type == 'size') {
                this.inputSelect = this.options.find(obj => obj.size.id == this.control.value).size;
            }
            else this.inputSelect = this.options.find(obj => obj.id == this.control.value);
        }
    }

    propagateChange = (_: any) => {
    };

    registerOnChange(fn) {
        this.propagateChange = fn;
    }

    registerOnTouched() {
    }

    compareTech(t1, t2): boolean {
        return t1 && t2 ? t1.id === t2.id : t1 === t2;
    }

    selectItem(option) {
        if (this.form_group) {
            if(option.id === -1) {
                this.control.setValue(null);
            } else {
                this.control.setValue(option.id);
            }
        }
        if(option.id === -1) {
            this.propagateChange(null);
            this.valueChange.emit(null);
        } else {
            this.propagateChange(option.id);
            this.valueChange.emit(option.id);
        }
        this.inputSelect = option;
        var checkboxes = document.getElementById("checkboxes-"+this.formControlName);
        // checkboxes.style.display = "none";
        this.isExpanded = false;

    }

    clickOutside() {
        let self = this;
        $(document).on('click', function (e) {
            if (self.element !== e.target && !self.element[0].contains(e.target)) {
                    var checkboxes = document.getElementById("checkboxes-"+self.formControlName);
                    // checkboxes.style.display = "none";
                    self.isExpanded = false;
                 }
        }); 
    }
}
