import { FilterService } from './../../../_services/filter';
import { ReloadService } from './../../../_services/reload';
import { TranslationService } from './../../../_services/translation';
import { CustomValidator } from './../../../_services/validators';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CartService } from './../../../_services/cart';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'shop-header',
    templateUrl: './shop-header.component.html',
    styleUrls: ['./shop-header.component.sass']
})
export class ShopHeaderComponent implements OnInit {
    constructor(
        public router: Router,
        private cartService: CartService,
        private route: ActivatedRoute,
        private translationService: TranslationService,
        private reloadService: ReloadService,
        private filterService: FilterService
    ) { }

    search = new FormGroup({
        search_q: new FormControl('', [CustomValidator.isBlank])
    })

    currentLanguage: string = 'en';
    num: number = 0;
    showSearchButton: boolean = false;
    ngOnInit(): void {

        this.translationService.currentLanguage.subscribe(value => {
            this.currentLanguage = value;
        })
        this.bgClick();
        this.cartService.currentItems.subscribe(items => {
            this.num = items.filter(e => e.options.option === 'buy').length;
        });
        this.search.valueChanges.subscribe(x => {
            x.search_q ? this.showSearchButton = true : this.showSearchButton = false;
        })
     }

     changeLanguage(lang_code: string) {
        this.translationService.changeLanguage(lang_code);
        this.currentLanguage = this.translationService.getLanguage();
     }

    clearFilters() {
        this.filterService.setFilters(null);
        this.filterService.setCategory(null);
    }

    moveTo(path: string) {
        this.clearFilters();
        this.closeNav();
        this.router.navigate([path],{queryParams: {}});
    }

     cartPreview() {
        if(this.router.url.indexOf('/product') > -1) {
            this.cartService.openCartPreview();
        }
        else {
            this.openCart();
        }
     }

     openCart() {
         this.router.navigate(['shop/cart'])
     }

     bgClick() {
        $("#search").click(function(e){
            var target = $(e.target);
            if (!target.closest('.search-body').length) {
                target.fadeOut('fast');
            }
        });
     }

     openSearch() {
        $('#search').fadeIn("fast").css('display', 'flex');
        $('#search input').focus();
    }

    onSearch() {
        $("#search").fadeOut('fast');
        this.router.navigate(['shop/catalog'], {queryParams: {search: this.search.controls.search_q.value}});
        this.search.reset();
    }

    closeNav() {
        $('#overlay-nav').css('width', '0');
    }

    openNav() {
        $('#overlay-nav').css('width', '100%');
    }
}
