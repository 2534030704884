import { CartService } from './cart';
import { TranslationService } from './translation';
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserAuthInfo } from '../_models/user';
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';
const STORAGE_KEY_AUTH_USER = 'SCORER_CURRENT_USER';
const STORAGE_KEY_CART = 'SCORER_CART';
const SCORER_LANG = 'LANG_CODE';


@Injectable()
export class AuthenticationService {
    constructor(
        private http: HttpClient,
        private translationService: TranslationService,
        private cartService: CartService,
        private router: Router,
        @Inject(PLATFORM_ID) private platformId: Object
    ) {
    }

    login(email: string, password: string) {
        return this.http.post<any>('login/email/', { email: email, password: password }, {
            headers: new HttpHeaders().set('Accept-Language', this.translationService.getLanguage())
        })
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                if (user && user.token && isPlatformBrowser(this.platformId)) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem(STORAGE_KEY_AUTH_USER, JSON.stringify(user));
                }

                return user;
            }));
    }

    loginFacebook(access_token: string) {
        return this.http.post<any>('auth/facebook/', { access_token: access_token })
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                if (user && user.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem(STORAGE_KEY_AUTH_USER, JSON.stringify(user));
                }
                return user;
            }));
    }

    loginInstagram(access_token: string) {
        return this.http.post<any>('auth/instagram/', { access_token: access_token })
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                if (user && user.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem(STORAGE_KEY_AUTH_USER, JSON.stringify(user));
                }
                return user;
            }));
    }

    instagramToken(token: string){
        return this.http.post<any>('instragram/token/', {
            token: token
        })
    }

    getInstagramDetail(code: string, clientid: string, secretkey: string, redirecturl: string){

        const body = new HttpParams()
            .set('client_id', clientid)
            .set('client_secret', secretkey)
            .set('grant_type', 'authorization_code')
            .set('redirect_uri', redirecturl + '/')
            .set('code', code)

        return this.http.post('https://api.instagram.com/oauth/access_token',
            body.toString(),
            {
                headers: new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded')
            }
        );
    }

    getInstagramFields(code: string, userid: string){
        return this.http.get('https://graph.instagram.com/me?fields=id,username&access_token=' + code);
    }

    getInstagramData(username: string){
        return this.http.get('https://www.instagram.com/'+username+'/?__a=1');
    }

    getInstagramUser(username: string){
        return this.http.get(`instagram?username=${username}`);
    }

    postInstagramUser(username: string){
        return this.http.post<any>('instagram/', {
            username: username
        })
    }

    getGreenList(username: string){
        return this.http.get(`greenlist?username=${username}`);
    }

    loginInstagramUser(username: string){
        return this.postInstagramUser(username).subscribe(
			data => {
                localStorage.setItem(STORAGE_KEY_AUTH_USER, JSON.stringify(data));
				this.router.navigate(['shop/catalog/main']);
			}
		);
    }


    InstagramUser(username){
        this.getInstagramUser(username).subscribe(
			instagramUser =>{
				if (instagramUser == "No user found"){
					//No user found 
					this.router.navigate(['registration/instagram/' + username]);
				}
				else{
					let isApproved = instagramUser['influencer_profile']['instagram_url_moderation_status'] == "approved";
					if (isApproved){
						//Login
						this.loginInstagramUser(username);
					}
					else{
						this.router.navigate(['account/waiting/']);
					}
					
				}
			}
		);
    }


    logout() {
        if (isPlatformBrowser(this.platformId)) {
            localStorage.removeItem(STORAGE_KEY_AUTH_USER);
            localStorage.removeItem(STORAGE_KEY_CART);
            localStorage.removeItem(SCORER_LANG);

            this.cartService.removeAllItems();
            this.translationService.changeLanguage('de');
        }
        // remove user from local storage to log user out
    }

    getCurrentUser(): UserAuthInfo {
        if (isPlatformBrowser(this.platformId)) {
            return JSON.parse(localStorage.getItem(STORAGE_KEY_AUTH_USER) || "null");
        }
    }

    saveCurrentUserAuthInfo(user: UserAuthInfo) {
        if (isPlatformBrowser(this.platformId)) {
            localStorage.setItem(STORAGE_KEY_AUTH_USER, JSON.stringify(user));
        }
    }

    getCurrentUserToken() {
        if (isPlatformBrowser(this.platformId)) {
            return JSON.parse(localStorage.getItem(STORAGE_KEY_AUTH_USER) || "null")['token'];
        }
    }

    requestPassword(email: string) {
        return this.http.post('request-password-change/', { email: email }, {
            headers: new HttpHeaders().set('Accept-Language', this.translationService.getLanguage())
        });
    }

    changePassword(token: string, pass: string) {
        return this.http.post('change-password-by-token/', {
            token: token,
            new_password: pass
        })
    }

}
