import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
	selector: 'registration-header',
	templateUrl: './registration-header.component.html',
	styleUrls: ['./registration-header.component.sass']
})
export class RegistrationHeaderComponent implements OnInit {
	url = '';
	constructor(private router: Router) { }
	currentSection: number = 1;
	ngOnInit(): void {
		this.url = this.router.url;
		this.router.events.subscribe((e) => {
			if (e instanceof NavigationEnd) {
				this.url = e.url;
			}
		});
	}
}
