import { ControlValueAccessor, NG_VALUE_ACCESSOR, AbstractControl, ControlContainer } from '@angular/forms';
import { Component, OnInit, forwardRef, Output, EventEmitter, SkipSelf, Host, Optional, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-size-items',
  templateUrl: './size-items.component.html',
  styleUrls: ['./size-items.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SizeItemsComponent),
      multi: true
    }
  ]
})
export class SizeItemsComponent implements ControlValueAccessor, OnInit, OnChanges {
  inputStock: any = [];
  @Input() formControlName: string;
  @Input() sizeControlName: string;
  @Input() class = 'form-control'
  @Input() label = '';
  @Input() placeholder = '';
  @Input() sizes;
  @Input() stock;
  @Input() index;
  @Input() size_list;
  //@Input() currencyOptions: any;

  inputCurrency: any;
  currentLanguage: string = 'en';

  @Output() private valueChange = new EventEmitter();
  control: AbstractControl;
  controlSize: AbstractControl;
  //currencyControl: AbstractControl;

  constructor(
    @Optional() @Host() @SkipSelf()
    private controlContainer: ControlContainer,
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.stock) {
      if (this.sizes && this.sizes.every(e => e.stock !== undefined) && this.inputStock.every(e => e.stock < 10000)) {
        this.control.setErrors(null);
      }
    }
    if (changes.sizes) {
      this.inputStock = [];
      for (let i = 0; i < changes.sizes.currentValue.length; i++) {
        if (changes.sizes.currentValue[i].selected) {
          this.inputStock.push({
            size_id: changes.sizes.currentValue[i].id,
            stock: changes.sizes.currentValue[i].stock
          })
        }
      }
      this.sizes = changes.sizes.currentValue.filter(e => e.selected)

      if (this.inputStock.every(e => e.stock !== undefined) && this.inputStock.every(e => e.stock < 10000)) {
        this.controlSize.setErrors(null)
      }
      //this.sizes =  changes.sizes.currentValue.filter(e => e && e.stock);
      this.propagateChange(this.inputStock);
    }


  }
  writeValue(value: any) {
    if (value !== undefined) {
      this.inputStock = value;
    }
  }

  onInputChange() {
    this.inputStock = [];
    for (let i = 0; i < this.sizes.length; i++) {
      this.inputStock.push({
        size_id: this.sizes[i].id,
        stock: this.sizes[i].stock
      })
    }
    if (this.inputStock.every(e => e.stock !== undefined) && this.inputStock.every(e => e.stock < 10000)) {
      this.controlSize.setErrors(null)
    }
    this.propagateChange(this.inputStock);
  }

  propagateChange = (_: any) => { };

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() { }

  ngOnInit(): void {
    if(this.size_list) {
      this.sizes = this.size_list.filter(e => e.selected)
    }
    //this.sizes = this.size_list.filter(e => e.selected)
    if (this.controlContainer) {
      if (this.formControlName) {
        this.control = this.controlContainer.control.get(this.formControlName);
        this.controlSize = this.controlContainer.control.get(this.sizeControlName);
        this.control.valueChanges.subscribe(x => {
          this.inputStock = this.control.value;
        })
        if (this.stock) {
          this.inputStock = [];
          //this.sizes = [];
          for (let i = 0; i < this.stock.length; i++) {
            this.inputStock.push({
              size_id: this.stock[i].size.id,
              stock: this.stock[i].stock
            })
          }
          this.sizes = this.sizes.map(s => {
            let found = this.inputStock.find(e => e.size_id == s.id);
            if (found) {
              s.stock = found.stock;
              return s;
            }
          }).filter(e => e);
          this.propagateChange(this.inputStock);
        }

      } else {
        console.warn('Missing FormControlName directive from host element of the component');
      }
    } else {
      console.warn('Can\'t find parent FormGroup directive');
    }
  }

  setDisabledState(isDisabled: boolean): void { }

  onKeyPressed(event: any) {
    var pattern;
    pattern = /^[0-9]$/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }


}