import { ModalService } from './../../../_services/modal';
import { Component, Inject, Input, EventEmitter, Output, forwardRef, OnInit, SkipSelf, Host, Optional } from '@angular/core';
import { AbstractControl, ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'app-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.sass'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CheckboxComponent),
            multi: true
        }
    ]
})
export class CheckboxComponent implements ControlValueAccessor, OnInit {
    inputText: boolean;
    @Input() formControlName: string;
    @Input() class = 'form-control'
    @Input() label = '';
    @Input() placeholder = '';
    @Input() text = 'text.of-scorerclub'
    @Input() caption;

    control: AbstractControl;

    constructor(
        @Optional() @Host() @SkipSelf()
        private controlContainer: ControlContainer,
        private modalService: ModalService
    ) { }

    openModal(id: string) {
        this.modalService.open(id);
    }
    writeValue(value: any) {
        if (value !== undefined) {
            this.inputText = value;
        }
    }

    onInputChange() {
        this.propagateChange(this.inputText)
    }

    propagateChange = (_: any) => { };

    registerOnChange(fn) {
        this.propagateChange = fn;
    }

    registerOnTouched() { }

    ngOnInit(): void {
        if (this.controlContainer) {
            if (this.formControlName) {
                this.control = this.controlContainer.control.get(this.formControlName);
                this.control.valueChanges.subscribe(x => {

                })
            } else {
                console.warn('Missing FormControlName directive from host element of the component');
            }
        } else {
            console.warn('Can\'t find parent FormGroup directive');
        }
    }

    setDisabledState(isDisabled: boolean): void { }

}
