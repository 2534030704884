import {Component, OnInit, Input, forwardRef, Optional, Host, SkipSelf, EventEmitter, Output, SimpleChanges} from '@angular/core';
import {
    AbstractControl,
    ControlContainer,
    ControlValueAccessor,
    FormArray,
    FormControl,
    FormGroup,
    NG_VALUE_ACCESSOR
} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'app-date-select',
    templateUrl: './date-select.component.html',
    styleUrls: ['./date-select.component.sass'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DateSelectComponent),
            multi: true
        }
    ]
})
export class DateSelectComponent implements ControlValueAccessor, OnInit {
    inputText: any = {};
    inputDay: any;
    inputMonth: any;
    inputYear: any;
    @Input() label = '';
    @Input() options: any = []; 
    @Input() disabled = false;
    @Input() formControlName: string;
    @Input() placeholder: string = '';
    @Input() capture: string = '';
    @Input('group')
    public form_group: FormGroup;
    @Output() private valueChange = new EventEmitter();
    productId: string; // todo: remove
    control: AbstractControl;
    years: any = [];
    months: any = [
        {id: 0, name: 'January', translate: 'text.january'}, 
        {id: 1, name: 'February', translate: 'text.february'}, 
        {id: 2, name: 'March', translate: 'text.march'}, 
        {id: 3, name: 'April', translate: 'text.april'}, 
        {id: 4, name: 'May', translate: 'text.may'}, 
        {id: 5, name: 'June', translate: 'text.june'}, 
        {id: 6, name: 'July', translate: 'text.july'}, 
        {id: 7, name: 'August', translate: 'text.august'}, 
        {id: 8, name: 'September', translate: 'text.september'}, 
        {id: 9, name: 'October', translate: 'text.october'}, 
        {id: 10, name: 'November', translate: 'text.november'}, 
        {id: 11, name: 'December', translate: 'text.december'}
    ];
    days: any = [];

    constructor(@Optional() @Host() @SkipSelf()
                private controlContainer: ControlContainer,
                private router: Router,
                private route: ActivatedRoute) {
    }

    static createFormGroup(initial_value?: any): FormControl {

            return new FormControl( {});

    }

    populateYears(startYear = 1950) {
        var currentYear = new Date().getFullYear();
            startYear = startYear || 1950;

            while ( startYear <= currentYear ) {
                    this.years.push(currentYear--);
            } 
    }
    
    ngOnInit(): void {    
        this.days = Array.apply(null, {length: 32}).map(Number.call, Number);
        this.days.shift();
        this.populateYears();
        if (this.controlContainer) {
            if (this.formControlName) {
                this.control = this.controlContainer.control.get(this.formControlName);

                if(Object.prototype.toString.call(this.control.value) === '[object Date]') {
                    this.inputYear = this.control.value.getFullYear();
                    this.inputDay = this.control.value.getDate();
                    this.inputMonth = this.months.find(obj => {return obj.id === this.control.value.getMonth()});
                }
                        this.control.valueChanges.subscribe(x => {
                            if (typeof(this.control.value) !== 'object') {
                                    let date = new Date(this.control.value);
                                    this.inputYear = date.getFullYear();
                                    this.inputDay = date.getDate();
                                    this.inputMonth = this.months.find(obj => {return obj.id === date.getMonth()});
                        } 
                        }); 
            } else {
                console.warn('Missing FormControlName directive from host element of the component');
            }
        } else {
            console.warn('Can\'t find parent FormGroup directive');
        }
        if (this.form_group) {
            this.control = this.form_group;
        }

    }

    setDisabledState(isDisabled: boolean): void {
    }

    writeValue(value: any) {
        this.inputText = value;
    }

    propagateChange = (_: any) => {
    };

    registerOnChange(fn) {
        // todo: find why (change)="myHandler($event)" is gets "str value of input", not the actual value
        // https://blog.angularindepth.com/never-again-be-confused-when-implementing-controlvalueaccessor-in-angular-forms-93b9eee9ee83
        this.propagateChange = fn;
    }

    registerOnTouched() {
    }

    compareTech(t1, t2): boolean {
        return t1 && t2 ? t1.id === t2.id : t1 === t2;
    }

    onChange() {
        if(this.inputDay && this.inputMonth && this.inputYear) {
            this.control.setValue(new Date(Date.UTC(this.inputYear, this.inputMonth.id, this.inputDay)))
        }
    }
}
