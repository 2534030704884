import {AbstractControl, FormGroup} from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable()
export class FormService {
    // get all values of the formGroup, loop over them
    // then mark each field as touched

    public markTouched(formGroup: FormGroup) {
        (<any>Object).values(formGroup.controls).forEach(control => {
            control.markAsTouched();
            control.pristine = false;
            if (control.controls) {
                this.markTouched(control);
            }
        });
    }

    public controlTouсhed(control) {
        control.markAsTouched();
        control.pristine = false;
        control.errors = null;
        control.status = 'VALID';
        control.value.length = 1;
    }

    resetErrors(formGroup: FormGroup){
        (<any>Object).values(formGroup.controls).forEach(control => {
            control.setErrors(null);
            if (control.controls) {
                this.resetErrors(control);
            }
        });
    }

    public validationMessages() {
        const messages = {
            required: 'This field is required',
            email: 'This email address is invalid',
            minlength: 'Please, enter more then 5 letters',
            invalid_characters: (matches: any[]) => {

                let matchedCharacters = matches;

                matchedCharacters = matchedCharacters.reduce((characterString, character, index) => {
                    let string = characterString;
                    string += character;

                    if (matchedCharacters.length !== index + 1) {
                        string += ', ';
                    }

                    return string;
                }, '');
                return `These characters are not allowed: ${matchedCharacters}`;
            },
        };

        return messages;
    }

    // Validate form instance
    // check_dirty true will only emit errors if the field is touched
    // check_dirty false will check all fields independent of
    // being touched or not. Use this as the last check before submitting
    public validateForm( formToValidate: FormGroup, formErrors: any, checkDirty?: boolean) {
        const form = formToValidate;
        for (const field in formErrors) {
            if (field) {
                formErrors[field] = '';
                const control = form.get(field);
                const messages = this.validationMessages();
                if (control && !control.valid) {
                    if (!checkDirty || (control.dirty || control.touched)) {
                        for (const key in control.errors) {
                            if (key && key !== 'invalid_characters') {
                                formErrors[field] = formErrors[field] || messages[key];
                            } else {
                                formErrors[field] = formErrors[field] || messages[key](control.errors[key]);
                            }
                        }
                    }
                }
            }
        }

        return formErrors;
    }
}