import { AbstractControl, ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Component, OnInit, Input, Optional, Host, SkipSelf, forwardRef } from '@angular/core';

@Component({
    selector: 'app-color-picker',
    templateUrl: './color-picker.component.html',
    styleUrls: ['./color-picker.component.sass'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ColorPickerComponent),
            multi: true
        }
    ]
})
export class ColorPickerComponent implements ControlValueAccessor, OnInit {
    inputRadio: any = {}
    @Input() options: any = [];
    @Input() formControlName: string;
    @Input() class = 'form-control'
    @Input() label = '';
    @Input() name = '';

    control: AbstractControl;

    constructor (
        @Optional() @Host() @SkipSelf()
        private controlContainer: ControlContainer
    ) {}

    writeValue(value: any) {
        if (value !== undefined) {
        }
    }

    onInputChange(){
        this.control.setValue(this.inputRadio);
        this.propagateChange(this.inputRadio)
    }

    propagateChange = (_: any) => {};

    registerOnChange(fn) {
        this.propagateChange = fn;
    }

    registerOnTouched() {}

    ngOnInit(): void {
        if (this.controlContainer) {
            if (this.formControlName) {
                this.control = this.controlContainer.control.get(this.formControlName);
                this.control.valueChanges.subscribe(x => {
                    this.inputRadio = this.control.value;
                })
                this.inputRadio = this.control.value;
            } else {
                console.warn('Missing FormControlName directive from host element of the component');
            }
        } else {
            console.warn('Can\'t find parent FormGroup directive');
        }
    }

    valueChanged(){
        this.control.setValue(this.inputRadio);
    }
    setDisabledState(isDisabled: boolean): void {}
}
