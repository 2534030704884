import { TranslationService } from './../../../_services/translation';
import { Component, Inject, Input, EventEmitter, Output, forwardRef, OnInit, SkipSelf, Host, Optional } from '@angular/core';
import { AbstractControl, ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'app-radio',
    templateUrl: './radio.component.html',
    styleUrls: ['./radio.component.sass'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RadioComponent),
            multi: true
        }
    ]
})
export class RadioComponent implements OnInit {
    inputRadio: any;
    @Input() options: any = [];
    @Input() formControlName: string;
    @Input() class = 'form-control'
    @Input() container = ''
    @Input() label = '';
    @Input() name = '';
    @Input() type = 'regular'
    @Input() hint;

    control: AbstractControl;
    currentLanguage: string = 'en';
    showHint: boolean = false;

    //translation
    with: string;

    constructor (
        @Optional() @Host() @SkipSelf()
        private controlContainer: ControlContainer,
        private translationService: TranslationService
    ) {}

    writeValue(value: any) {
        if (value !== undefined) {
        }
    }

    onInputChange(){
        this.control.setValue(this.inputRadio)
        //this.inputRadio == 'true' ? this.control.setValue(true) : this.control.setValue(false);
        this.propagateChange(this.inputRadio)
    }

    propagateChange = (_: any) => {};

    registerOnChange(fn) {
        this.propagateChange = fn;
    }

    registerOnTouched() {}

    ngOnInit(): void {
        this.translationService.currentLanguage.subscribe(value => {
            this.currentLanguage = value;
        })

        if (this.controlContainer) {
            if (this.formControlName) {
                this.control = this.controlContainer.control.get(this.formControlName);
                this.control.valueChanges.subscribe(x => {
                    this.inputRadio = this.control.value;
                })
                this.inputRadio = this.control.value;
            } else {
                console.warn('Missing FormControlName directive from host element of the component');
            }
        } else {
            console.warn('Can\'t find parent FormGroup directive');
        }
    }

    valueChanged(){
        this.control.setValue(this.inputRadio);
    }
    setDisabledState(isDisabled: boolean): void {}

    displayHint(e: Event) {
        e.stopPropagation();
        e.preventDefault();
        this.showHint = !this.showHint;
    }

    getTypeOfPost(type: string, count: number): string{
        let title: string = type;
        if (count > 1){
            title = title + 's';
        }
        return title;
    }
}
