import { ProductComponent } from './../../product/product.component';
import { TranslationService } from './../../../../_services/translation';
import { AuthenticationService } from './../../../../_services/authentification';
import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-product-card',
    templateUrl: './product.component.html',
    styleUrls: ['./product.component.sass']
})
export class ProductCardComponent implements OnInit {
    @Input() product: any;
    rating: string;
    currentLanguage: string = 'en';
    maxPostQty: number = 0;
    isLiableForVat: boolean = false;
    isWithSpecialDeal: boolean = false;
    isBlur: boolean = false;
    minimumFollowerMessage: string = '';
    deals: any = [];
    buyOnlyDiscount: number = 0;
    buyOnlyAmount: number = 0;

    influencerOptions = {
        "No Fanfluencer": {
            story: 0,
            feed: 0,
            reel: 0,
            text: 'discount_no_influencer'
        },
        "M-Fanfluencer": {
            story: 100,
            feed: 200,
            reel: 300,
            text: 'discount_nano_influencer'
        },
        "L-Fanfluencer": {
            story: 200,
            feed: 400,
            reel: 600,
            text: 'discount_micro_influencer'
        },
        "XL-Fanfluencer": {
            story: 500,
            feed: 1000,
            reel: 1500,
            text: 'discount_mega_influencer'
        },
        "XXL-InflFanfluenceruencer": {
            story: 1000,
            feed: 2000,
            reel: 3000,
            text: 'discount_macro_influencer'
        }
    }

    influencerDeals: any = [];

    constructor(
        private authenticationService: AuthenticationService,
        private translationService: TranslationService
    ) { }

    ngOnInit(): void {
        //temp 
        this.product.stock = this.product.size_items.reduce(function (a, b) {
            return a + b.stock
        }, 0);
        //
        let user = this.authenticationService.getCurrentUser().user.influencer;
        this.rating = user['rating'];
        this.isLiableForVat = user['is_liability_for_VAT'];
        // if(user['is_liability_for_VAT']) {
        //     Object.keys(this.influencerOptions).forEach(e => {
        //         this.influencerOptions[e]['discount'] += Math.ceil(this.influencerOptions[e]['discount'] * 0.19);
        //     })
        // }
        this.translationService.currentLanguage.subscribe(value => {
            this.currentLanguage = value;
        });

        //this.isWithSpecialDeal = this.product.influencer_deals.length > 0;

        //this.isBlur = (this.product.is_buy_only == false) && (this.product.is_influencer_offer == true);

        if (this.product.is_influencer_offer && this.rating != "No Fanfluencer") {
            for (let i = 0; i < this.product.posts_qty; i++) {
                this.maxPostQty++;
                if (this.getProductInfluencerPrice(i + 1) === 0) {
                    break;
                }
            }
        }

        if (this.product.is_buy_only) {
            this.buyOnlyDiscount = this.product[this.influencerOptions[this.rating].text];
            this.buyOnlyAmount = this.product.price - this.product.price * this.buyOnlyDiscount / 100;
        }

        if ((this.rating != "No Fanfluencer") && (this.product.is_influencer_offer))
            this.setDeals();

        this.IsThisItemAvailable();
        if (this.isBlur) {
            this.GetMinimumMessage();
        }
    }

    getInfluencerOption(type: string): any {
        return this.influencerDeals.filter(x => x.rating === this.rating).filter(x => x.post_type == type);
    }

    setDeals() {
        this.influencerDeals = this.product.influencer_deals;

        let story_deals = this.getInfluencerOption('story');

        if (story_deals.length > 0) {
            this.deals.push({
                post_count: story_deals[0].post_count,
                post_type: story_deals[0].post_type,
                price: story_deals[0].price
            });
        }
        else {
            if (this.product.posts_qty > 0) {
                this.deals.push({
                    post_count: 1,
                    post_type: 'story',
                    price: this.getProductInfluencerPrice(1)
                });
            }
        }

        let feed_deals = this.getInfluencerOption('feed');
        if (feed_deals.length > 0) {
            this.deals.push({
                post_count: feed_deals[0].post_count,
                post_type: feed_deals[0].post_type,
                price: feed_deals[0].price
            });
        }
        else {
            if (this.product.posts_feed_qty > 0) {
                this.deals.push({
                    post_count: 1,
                    post_type: 'feed',
                    price: this.getProductInfluencerPrice(1)
                });
            }
        }

        let reel_deals = this.getInfluencerOption('reel');
        if (reel_deals.length > 0) {
            this.deals.push({
                post_count: reel_deals[0].post_count,
                post_type: reel_deals[0].post_type,
                price: reel_deals[0].price
            });
        }
        else {
            if (this.product.posts_reel_qty > 0) {
                this.deals.push({
                    post_count: 1,
                    post_type: 'reel',
                    price: this.getProductInfluencerPrice(1)
                });
            }
        }
    }

    getPostTitle(type: string, count: number): string {
        let title: string = type;

        if (type == 'story')
            title = 'Story-Post';
        if (type == 'feed')
            title = 'Feed-Post';
        if (type == 'reel')
            title = 'Reel';

        if (count > 1) {
            title = title + 's';
        }
        return title;
    }

    IsThisItemAvailable() {
        if (this.product.is_buy_only || this.product.is_influencer_offer) {
            if (this.product.is_buy_only) {
                this.isBlur = false;
            }
            else if (this.product.is_influencer_offer) {
                if (this.rating == 'No Fanfluencer') {
                    this.isBlur = true;
                }
                else {
                    if (this.product.influencer_deals.length > 0) {
                        this.isBlur = this.product.influencer_deals.filter(x => x.rating == this.rating).length == 0
                    }
                    else {
                        this.isBlur = false;
                    }
                }
            }
        }
        else {
            this.isBlur = false;
        }
    }

    GetMinimumMessage() {
        let ratings = ['No Fanfluencer', 'M-Fanfluencer', 'L-Fanfluencer', 'XL-Fanfluencer', 'XXL-Fanfluencer'];
        let index = ratings.findIndex(x => x === this.rating);
        let next = index < ratings.length?ratings[index + 1]: -1;
        let minimum: number = 0;
        if (next === 'M-Fanfluencer'){
            minimum = 1001;
        }
        else if (next === 'L-Fanfluencer'){
            minimum = 10001;
        }
        else if (next === 'XL-Fanfluencer'){
            minimum = 75001;
        }
        else if (next === 'XXL-Fanfluencer'){
            minimum = 250001;
        }
        if (minimum > 0) {
            if (this.currentLanguage == 'en') {
                if (minimum == 1001)
                    this.minimumFollowerMessage = 'Available from 1,001 followers'
                if (minimum == 10001)
                    this.minimumFollowerMessage = 'Available from 10,001 followers'
                if (minimum == 75001)
                    this.minimumFollowerMessage = 'Available from 75,001 followers'
                if (minimum == 250001)
                    this.minimumFollowerMessage = 'Available from 250,001 followers'
            }
            else {
                if (minimum == 1001)
                    this.minimumFollowerMessage = 'Ab 1.001 Follower erhältlich'
                if (minimum == 10001)
                    this.minimumFollowerMessage = 'Ab 10.001 Follower erhältlich'
                if (minimum == 75001)
                    this.minimumFollowerMessage = 'Ab 75.001 Follower erhältlich'
                if (minimum == 250001)
                    this.minimumFollowerMessage = 'Ab 250.001 Follower erhältlich'
            }
        }
    }

    getPersonalPrice() {
        return this.influencerOptions[this.rating].discount;
    }

    getProductInfluencerPrice(post: number) {
        let price;
        price = this.product.price_with_discount - (post * this.getPersonalPrice());
        if (price > 0) return price;
        return 0;
    }
}
