import { DomSanitizer } from '@angular/platform-browser';
import { PipeTransform, Pipe, Sanitizer, SecurityContext } from '@angular/core';
@Pipe({
    name: 'bold',
    pure: false
})
export class BoldPipe implements PipeTransform {
    constructor(
        private sanitizer: DomSanitizer
    ){}
    transform(value: string, regex): any {
        if(value)
            return this.sanitizer.bypassSecurityTrustHtml(this.replace(value, regex));
        else return ''
    }

    replace(str, regex) {
        return str.replace(new RegExp(/\(([^)]+)\)/, 'gi'), '<span style="color: #000; font-weight: 500">$1</span>');
    }

    sanitize(str) {
        return this.sanitizer.sanitize(SecurityContext.HTML, str);
    }
}

