import { SharedModule } from './_pipes/pipes.module';
import { CookieService } from './_services/cookie';
import { NotFoundComponent } from './modules/404/not-found.component';
import { ReloadService } from './_services/reload';
import { FilterService } from './_services/filter';
import { DataService } from './_services/data';
import { CartService } from './_services/cart';
import { ProductService } from './_services/product';
import { ContentService } from './_services/content';

import { BrandGuard } from './_guards/brand.guard';
import { BrandService } from './_services/brand';
import { AuthGuard } from './_guards/auth.guard';
import { PreviousRouteService } from './_services/previous-route';
import { ShopHeaderComponent } from './modules/header/shop-header/shop-header.component';
import { ModalService } from './_services/modal';
import { FormService } from './_services/form';
import { HomeHeaderComponent } from './modules/header/home-header/home-header.component';
import { HeaderComponent } from './modules/header/header.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RegistrationHeaderComponent } from './modules/header/registration-header/registration-header.component';
import { AuthServiceConfig, SocialLoginModule } from 'angularx-social-login';
import { getAuthServiceConfigs } from './services/socialLoginConfig';
import { ApiPathInterceptor, JwtInterceptor } from './_helpers';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { RegistrationService } from './_services/registration';
import { AuthenticationService } from './_services/authentification';
import { UserService } from './_services/user';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslationService } from './_services/translation';
import { InfluencerGuard } from './_guards/influencer.guard';
import { DecimalPipe } from '@angular/common';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    RegistrationHeaderComponent,
    HomeHeaderComponent,
    ShopHeaderComponent,
    // EmailConfirmationComponent,
    // LoginComponent,
    // RecoverPasswordComponent,
    // HomeBrandComponent,
    // ChangeEmailComponent,
    NotFoundComponent,
  ],
  imports: [
    AppRoutingModule,
    //AngularFullpageModule,
    SocialLoginModule,
    HttpClientModule,
    BrowserModule.withServerTransition({ appId: 'ScorerClub' }),
    //BrowserAnimationsModule,
    // UserHomeRoutingModule,
    // ShopRoutingModule,
    SharedModule,
    // RegistrationRoutingModule,
    // BrandRoutingModule,
    // LandingRoutingModule
  ],
  exports: [
    //MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule
  ],
  providers: [
    FormService,
    ModalService,
    ProductService,
    CartService,
    ContentService,
    PreviousRouteService,
    {
      provide: AuthServiceConfig,
      useFactory: getAuthServiceConfigs
    },
    RegistrationService,
    AuthenticationService,
    AuthGuard,
    BrandGuard,
    InfluencerGuard,
    DataService,
    UserService,
    BrandService,
    TranslationService,
    FilterService,
    ReloadService,
    DecimalPipe,
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiPathInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
