import { TranslationService } from './../../../_services/translation';
import { CartService } from './../../../_services/cart';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-cart-preview',
    templateUrl: './cart-preview.component.html',
    styleUrls: ['./cart-preview.component.sass']
})
export class CartPreviewComponent implements OnInit {
    constructor(
        private cartService: CartService,
        private router: Router,
        private translationService: TranslationService
    ) { }

    items_buy: any = [];
    items: any = [];
    itemNum: number;
    posts_num: number;
    post_type: string;
    totalCost: number;
    totalBuyCost: number;
    currentLanguage: string = 'en';

    dealOldPrice: number;
    dealNewPrice: number;
    ngOnInit(): void {
        this.translationService.currentLanguage.subscribe(value => {
            this.currentLanguage = value;
        })
        this.cartService.currentItems.subscribe(items => {
            let total_buy = 0;
            let total = 0;
            let post = 0;
            let deal_old = 0;
            let deal_new = 0;
            this.items = items;
            this.itemNum = items.filter(e => e.options.option === 'buy').length;
            this.items.map (item => {
                if(item.options.option === 'buy') {
                    total_buy += (item.options.new_price * item.options.amount);
                }
                //else {
                //    post += item.options.num_of_posts;
                //    deal_old += item.options.old_price;
                //    deal_new += item.options.new_price;
                //}
                total += (item.options.new_price * item.options.amount);
            });
            this.posts_num = post;
            this.totalCost = total;
            this.totalBuyCost = total_buy;
            this.dealOldPrice = deal_old;
            this.dealNewPrice = deal_new;
            //this.totalCost = total.toFixed(2);
        })
    }


    hide() {
        $('.cart-bg').css('display', 'none');
    }

    cart() {
        this.router.navigate(['shop/cart'])
    }

    checkout() {
        this.router.navigate(['shop/cart'])
    }

    remove(item_id, option) {
        this.cartService.removeItem(item_id, option);
    }
}
