import { AbstractControl, ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter, Optional, Host, SkipSelf, forwardRef, SimpleChanges, SimpleChange } from '@angular/core';

@Component({
    selector: 'app-input-currency',
    templateUrl: './input-currency.component.html',
    styleUrls: ['./input-currency.component.sass'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputCurrencyComponent),
            multi: true
        }
    ]
})
export class InputCurrencyComponent implements ControlValueAccessor, OnInit {
    constructor(
        @Optional() @Host() @SkipSelf()
        private controlContainer: ControlContainer
    ) { }

    inputText = '';
    @Input() formControlName: string;
    @Input() label = '';
    @Input() currency = '€';
    @Input() placeholder = this.currency + "0.00";
    @Input() disabled = false;

    @Output() private valueChange = new EventEmitter();
    control: AbstractControl;

    ngOnChanges(changes: SimpleChanges) {
        const currency: SimpleChange = changes.currency;
        this.currency = currency.currentValue;
        this.placeholder = this.currency + "0.00";
        $("input[data-type='currency']").each((i, input) => {
            this.formatCurrency($(input), "keyup");
        });

    }

    ngOnInit(): void {
        var self = this;
        $("input[data-type='currency']").on({
            keyup: function() {
              self.formatCurrency($(this), "keyup");
            },
            blur: function() { 
              self.formatCurrency($(this), "blur");
            }
        });
        
        if (this.controlContainer) {
            if (this.formControlName) {
                this.control = this.controlContainer.control.get(this.formControlName); 
                this.control.valueChanges.subscribe(x => {
                })
            } else {
                console.warn('Missing FormControlName directive from host element of the component');
            }
     }
    }

    onKeyPressed(event: any) {
        const pattern = /^[0-9.]*$/;
        let inputChar = String.fromCharCode(event.charCode);
        if (!pattern.test(inputChar)) {
            event.preventDefault();
        }
    }

    writeValue(value: any) {
        if (value !== undefined) {
            this.inputText = value;
        }
    }

    onInputChange(){
        this.propagateChange(+this.inputText.split(this.currency).pop().replace(/[,]/g, ""))
    }

    propagateChange = (_: any) => {};

    registerOnChange(fn) {
        this.propagateChange = fn;
    }

    registerOnTouched() {}

    formatNumber(n) {
        // format number 1000000 to 1,234,567
        return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
      
      
    formatCurrency(input, blur) {
        var input_val = input.val();
        
        if (input_val === "") { return; }
        var original_len = input_val.length;
        var caret_pos = input.prop("selectionStart");
        if (input_val.indexOf(".") >= 0) {
            var decimal_pos = input_val.indexOf(".");
        
            // split number by decimal point
            var left_side = input_val.substring(0, decimal_pos);
            var right_side = input_val.substring(decimal_pos);
        
            // add commas to left side of number
            left_side = this.formatNumber(left_side);
        
            // validate right side
            right_side = this.formatNumber(right_side);
            
            // On blur make sure 2 numbers after decimal
            if (blur === "blur") {
            right_side += "00";
            }
            
            // Limit decimal to only 2 digits
            right_side = right_side.substring(0, 2);
            this.currency == "€" ? (input_val = "€" + left_side + "." + right_side)
                                 : (input_val = "$" + left_side + "." + right_side)
        
        } else {
            // no decimal entered
            // add commas to number
            // remove all non-digits
            input_val = this.formatNumber(input_val);
            this.currency == "€" ? (input_val = "€" + input_val)
                                 : (input_val = "$" + input_val);

            
            // final formatting
            if (blur === "blur") {
            input_val += ".00";
            }
        }
        
        // send updated string to input
        input.val(input_val);
        
        // put caret back in the right position
        var updated_len = input_val.length;
        caret_pos = updated_len - original_len + caret_pos;
        input[0].setSelectionRange(caret_pos, caret_pos);
    }
}
