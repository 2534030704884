import { TranslationService } from './../../../_services/translation';
import { Component, OnInit, Input, forwardRef, Optional, Host, SkipSelf, EventEmitter, Output, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import {
    AbstractControl,
    ControlContainer,
    ControlValueAccessor,
    FormArray,
    FormControl,
    FormGroup,
    NG_VALUE_ACCESSOR
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { pureObjectDef } from '@angular/core/src/view';

@Component({
    selector: 'app-select-multiple',
    templateUrl: './select-multiple.component.html',
    styleUrls: ['./select-multiple.component.sass'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SelectMultipleComponent),
            multi: true
        }
    ]
})
export class SelectMultipleComponent implements ControlValueAccessor, OnInit {
    @ViewChild("selectBox") selectBox: ElementRef;
    inputText: any = {};
    inputSelect: any;
    @Input() label = '';
    @Input() options: any = [];
    @Input() disabled = false;
    @Input() formControlName: string;
    @Input() placeholder: any = {}
    @Input() type: string = "select";   
    @Input() edit = false;
    @Input() size = false;
    @Input('group')
    public form_group: FormGroup;
    @Output() private valueChange = new EventEmitter();
    control: AbstractControl;

    isExpanded: boolean = false;

    array: any = [];
    displayArray: any = [];
    element: any;

    currentLanguage: string = 'en';

    constructor(@Optional() @Host() @SkipSelf()
    private controlContainer: ControlContainer,
        private router: Router,
        private el: ElementRef,
        private route: ActivatedRoute,
        private translationService: TranslationService) {
        this.element = $(el.nativeElement);
    }

    static createFormGroup(initial_value?: any): FormControl {

        return new FormControl({});

    }

    showCheckboxes(event) {
        event.stopPropagation();
        if (!this.isExpanded) {
            this.isExpanded = true;
        } else {
            this.isExpanded = false;
        }
    }

    clickOutside() {
        let self = this;
        $(document).on('click', function (e) {
            if (self.element !== e.target && !self.element[0].contains(e.target)) {
                self.isExpanded = false;
            }

        });

    }

    highlight(field) {
        field.className = '';
    }
    ngOnInit(): void {
        this.translationService.currentLanguage.subscribe(value => {
            this.currentLanguage = value;

            if (value === 'en') {
                this.displayArray = this.array.map(x => x.name)
            } else {
                this.displayArray = this.array.map(x => x.name_german || x.name)
            }
        })
        this.clickOutside();
        if (this.controlContainer) {
            if (this.formControlName) {
                this.control = this.controlContainer.control.get(this.formControlName);
                if (this.control.value) {
                    if (this.options && this.options.length) {
                        this.inputSelect = this.options.filter(obj => obj.id == this.control.value);
                        this.array = this.options.filter(x => x.id == this.control.value.find(e => e == x.id));
                        this.control.setValue(this.array)
                    }
                }
                if (this.options && this.options.length) {
                    this.options.map((x, index) => {
                        if (this.array.find(e => e.id == x.id)) {
                            this.options[index].selected = true;
                        }
                    })
                    if(this.options.length-1 == this.control.value.length || !this.control.value.length) {
                        this.options.map(e => e.selected = true)
                        this.array = Object.assign(this.array, this.options).filter(x => x.id == 0 || x.id == -1)
                    }

                    // if (this.options.every(element => element.id !== -1 && element.id !== 0 && element.selected)) {
                    //     this.array = Object.assign(this.array, this.options).filter(x => x.id == 0 || x.id == -1)
                    // }

                    // if (this.currentLanguage === 'en') {
                    //     this.displayArray = this.array.map(x => x.name)
                    // } else {
                    //     this.displayArray = this.array.map(x => x.name_german || x.name)
                    // }
                    //this.array = this.options.filter(e => e.selected);
                    // this.displayArray = this.array.map(x => x.name)

                }
                if(this.size) {
                    this.array = this.options.filter(e => e.stock);
                    this.options = this.options.map(e => {
                        if(!e.stock)
                            e.selected = false
                        return e
                    })
                    this.displayArray = this.array.map(x => x.name);

                    this.propagateChange(this.options.filter(e => e.stock));
                    this.valueChange.emit(this.options.filter(e => e.stock));
                    // if (this.currentLanguage === 'en') {
                    //     this.displayArray = this.array.map(x => x.name)
                    // } else {
                    //     this.displayArray = this.array.map(x => x.name_german || x.name)
                    // }
                    // this.displayArray = this.array.map(x => x.name)
                }
                // this.displayArray = this.array.map(x => x.name)
                this.control.valueChanges.subscribe(x => {
                    if (!x || !x.length) {
                        this.options.map(x => x.selected = false);
                        this.array = [];
                        this.displayArray = [];
                    }
                    else {
                        this.options.map((x) => {
                            if (this.control.value && this.control.value.find(e => e == x.id)) {
                                x.selected = true;
                            }
                        });


                    }
                    // this.options.map((x) => {
                    //     if(this.control.value && this.control.value.find(e => e == x.id)) {
                    //         x.selected = true;
                    //     }
                    // })

                    if (typeof (this.control.value) === 'object') {
                        if (x !== undefined) {
                            let find = this.options.filter(z => z.name === x);
                        }
                    }
                });
                this.displayArray = this.array.map(x => x.name)

            } else {
                console.warn('Missing FormControlName directive from host element of the component');
            }


        } else {
            console.warn('Can\'t find parent FormGroup directive');
        }
        if (this.form_group) {
            this.control = this.form_group;
        }
    }

    setDisabledState(isDisabled: boolean): void {
    }

    writeValue(value: any) {
        if (value) {
            this.array = this.options.filter(x => {
                let val = value.find(e => {
                    if (e.id) {
                        return e.id == x.id
                    }
                    else {
                        return e == x.id
                    }
                })
                if (val) {
                    if (val.id) {
                        return x.id == val.id
                    }
                    else return x.id == val
                }
            })
        }

        if (this.type === 'filter') {
            this.options.map((x, index) => {
                if (this.array.find(e => e.id == x.id)) {
                    this.options[index].selected = true;
                }
                else this.options[index].selected = false;
            })
        }
        this.inputText = value;
    }

    propagateChange = (_: any) => {
    };

    registerOnChange(fn) {
        // todo: find why (change)="myHandler($event)" is gets "str value of input", not the actual value
        // https://blog.angularindepth.com/never-again-be-confused-when-implementing-controlvalueaccessor-in-angular-forms-93b9eee9ee83
        this.propagateChange = fn;
    }

    registerOnTouched() {
    }

    compareTech(t1, t2): boolean {
        return t1 && t2 ? t1.id === t2.id : t1 === t2;
    }

    selectItem(value, isChecked, event) {
        event.preventDefault();
        event.stopPropagation();

        // if(value.id !== 0 && value.id !== -1 && !isChecked) {
        //     this.array = this.array.filter(v => v.id !== 0 && v.id !== -1);
        //     this.options[0].selected = false;
        // }
        this.displayArray = [];
        if (isChecked) {
            if (value.id === 0 || value.id === -1) {
                this.options.forEach(element => {
                    element.selected = true;
                });
                this.array = Object.assign(this.array, this.options).filter(x => x.id == 0 || x.id == -1 )
            }
            else if(value.name === "One size") {
                this.array = Object.assign(this.array, this.options).filter(x => x.name === "One size");
                this.options.map(e => {
                    if(e.name == "One size")
                        e.selected = true;
                    else e.selected = false;
                })
            }
            else {
                if (this.options.every(element => element.id !== 0 && element.id !== -1 && element.selected)) {
                    this.array = Object.assign(this.array, this.options).filter(x => x.id == 0 || x.id == -1)
                }
                else {
                    this.array.push(value);
                    // if(this.size) {
                    //     this.array = this.array.filter((v, i, a) => a.indexOf(v) === i); 
                    // }
                    this.array = this.array.filter(x => x.name !== "One size")
                    this.options.forEach(element => {
                        if(element.name == "One size")
                            element.selected = false
                    });

                    this.array = this.options.filter(x => x.selected);
                }
            }
        }
        else {
            if (value.id === 0 || value.id === -1) {
                this.options.forEach(element => {
                    element.selected = false;
                });
                this.array = [];
            }
            else if(value.name == "One size") {
                this.options.map(e => {
                    if(e.name === "One size")
                        e.selected = false;
                })
                this.array = [];
                this.displayArray = [];
            }
            else {
                this.array = this.options.filter(v => v.id !== 0 && v.id !== -1 && v.id !== value.id && v.selected);
                if(!this.options.find(x => x.name === 'One size')) {
                   this.options[0].selected = false;
                }
            }
        }
        
        // if(isChecked && (value.id === 0 || value.id === -1)) {
        //     this.displayArray[0] = this.options[0];
        // }
        // if (!isChecked && (value.id === 0 || value.id === -1)) {
        //     this.displayArray = [];
        //     this.array = [];
        // }
        // else {
        if (this.options.filter(x => x.id !== 0 && x.id !== -1).every(x => x.selected)) {
            this.options[0].selected = true;
            //this.array = Object.assign(this.array, this.options).filter(x => x.id == 0 || x.id == -1);
        }
        if (this.currentLanguage === 'en') {
            this.displayArray = this.array.map(x => x.name)
        } else {
            this.displayArray = this.array.map(x => x.name_german || x.name)
        }
        //}
        if (this.form_group) {
            this.control.setValue(this.array);
        }
        
        this.propagateChange(this.array);
        this.valueChange.emit(this.array);
    }

    selectFilter(value, isChecked) {
        this.displayArray = [];
        if (isChecked) {
            if (!this.array.some(x => x.id === value.id)) {
                this.array.push(value);
            }
        }
        else {
            this.array = this.array.filter(v => v.id !== value.id)
        }

        if (this.currentLanguage === 'en') {
            this.displayArray = this.array.map(x => x.name)
        } else {
            this.displayArray = this.array.map(x => x.name_german || x.name)
        }

        if (this.form_group) {
            this.control.setValue(this.array);
        }
        this.propagateChange(this.array);
        this.valueChange.emit(this.array);
    }

    selectAll(e?) {
        if (this.options.length !== this.array.length) {
            this.options.forEach(element => {
                element.selected = true;
            });
            this.array = Object.assign(this.array, this.options);
        }
        else {
            this.array = [];
            this.options.forEach(element => {
                element.selected = false;
            });
        }
        this.propagateChange(this.array.map(x => x.id && x.id !== -1 && x.id !== 0));
        this.valueChange.emit(this.array.map(x => x.id && x.id !== -1 && x.id !== 0));
    }
}
