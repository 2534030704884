import { CdkDragDrop, moveItemInArray, CdkDropList, CdkDropListGroup, CdkDropListContainer, CdkDrag, CdkDragMove } from '@angular/cdk/drag-drop';

import { AuthenticationService } from './../../../_services/authentification';
import { Component, Inject, Input, EventEmitter, Output, forwardRef, OnInit, SkipSelf, Host, Optional, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { AbstractControl, ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UserService } from '../../../_services/user';
import { ViewportRuler } from '@angular/cdk/overlay';

@Component({
  selector: 'app-select-images',
  templateUrl: './select-images.component.html',
  styleUrls: ['./select-images.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectImagesComponent),
      multi: true
    }
  ]
})
export class SelectImagesComponent implements ControlValueAccessor, OnInit {
  @Input() formControlName: string;
  @Input() numOfImages: number = 4;
  @Input() label = '';
  @Input() main = 'Attach image';

  @ViewChild(CdkDropListGroup) listGroup: CdkDropListGroup<CdkDropList>;
  @ViewChild(CdkDropList) placeholder: CdkDropList;
  constructor(
    @Optional() @Host() @SkipSelf()
    private controlContainer: ControlContainer,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    public viewportRuler: ViewportRuler
  ) {
    this.target = null;
    this.source = null;
  }


  @Output() private valueChange = new EventEmitter();
  control: AbstractControl;
  files = [];
  images = [];
  sorted = [];
  imgError: any;
  sizeError: any;

  imgCounter = new Array(this.numOfImages-1);

  public target: CdkDropList;
  public targetIndex: number;
  public source: CdkDropList;
  public sourceIndex: number;
  public dragIndex: number;
  public activeContainer;

  ngAfterViewInit() {
    let phElement = this.placeholder.element.nativeElement;

    phElement.style.display = 'none';
    phElement.parentNode.removeChild(phElement);
  }

  // drop(event: CdkDragDrop<string[]>) {
  //     console.log(event);
  //     moveItemInArray(this.images, event.previousIndex, event.currentIndex);
  //     console.log(this.images)
  // }

  ngOnInit(): void {
    if (this.controlContainer) {
      if (this.formControlName) {
        this.control = this.controlContainer.control.get(this.formControlName);
        if (this.control.value && this.control.value.length) {
          this.images = this.control.value;
          this.sorted = this.control.value;
        }
      }
    }
  }

  openFile(i : number, evt) {
    document.getElementById(`${this.label}-img-input${i}`).click();
  }

  counter(i: number) {
    return new Array(i);
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.numOfImages) {
      this.imgCounter = new Array(changes.numOfImages.currentValue -1);
    }
  }

  readFile(event, index) {
    const file = event.target.files.item(0);
    if (!(/\.(gif|jpg|jpeg|png)$/i).test(file.name)) {
      this.imgError = true;
      return;
    }
    if (file.size > 2097152) {
      this.sizeError = true;
      return;
    }
    else {
      this.imgError = false;
      this.sizeError = false;
      var fd = new FormData();
      fd.append('file', file);
      this.userService.uploadImage(this.authenticationService.getCurrentUserToken(), fd)
        .subscribe(data => {
          this.images[index] = data;
          this.control.value[index] = data;
          if (this.control.value.length) {
            this.control.setErrors(null)
          }
        })
    }
  }


  writeValue(value: any) {
    if (value == null) {
      this.images = [];
    }
    else {
      Object.assign(this.images, this.control.value)
    }
  }

  propagateChange = (_: any) => {
  };

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() { }

  deleteImage(index, event) {
    event.stopPropagation();
    this.images[index] = null;
    delete this.control.value[index];
  }

  dragMoved(e: CdkDragMove) {
    let point = this.getPointerPositionOnPage(e.event);

    this.listGroup._items.forEach(dropList => {
      if (__isInsideDropListClientRect(dropList, point.x, point.y)) {
        this.activeContainer = dropList;
        return;
      }
    });
  }

  drop(e: Event) {
    if (!this.target)
      return;

    let phElement = this.placeholder.element.nativeElement;
    let parent = phElement.parentElement;

    phElement.style.display = 'none';

    parent.removeChild(phElement);
    parent.appendChild(phElement);
    parent.insertBefore(this.source.element.nativeElement, parent.children[this.sourceIndex]);

    this.target = null;
    this.source = null;

    if (this.sourceIndex != this.targetIndex) {
      moveItemInArray(this.images, this.sourceIndex, this.targetIndex);
      this.control.setValue(this.images);
    }
  }

  enter = (drag: CdkDrag, drop: CdkDropList) => {
    if (drop == this.placeholder)
      return true;

    if (drop != this.activeContainer)
      return false;

    let phElement = this.placeholder.element.nativeElement;
    let sourceElement = drag.dropContainer.element.nativeElement;
    let dropElement = drop.element.nativeElement;

    let dragIndex = __indexOf(dropElement.parentElement.children, (this.source ? phElement : sourceElement));
    let dropIndex = __indexOf(dropElement.parentElement.children, dropElement);

    if (!this.source) {
      this.sourceIndex = dragIndex;
      this.source = drag.dropContainer;

      phElement.style.width = sourceElement.clientWidth + 'px';
      phElement.style.height = sourceElement.clientHeight + 'px';

      sourceElement.parentElement.removeChild(sourceElement);
    }

    this.targetIndex = dropIndex;
    this.target = drop;

    phElement.style.display = '';
    dropElement.parentElement.insertBefore(phElement, (dropIndex > dragIndex
      ? dropElement.nextSibling : dropElement));

    this.placeholder.enter(drag, drag.element.nativeElement.offsetLeft, drag.element.nativeElement.offsetTop);
    return false;
  }

  /** Determines the point of the page that was touched by the user. */
  getPointerPositionOnPage(event: MouseEvent | TouchEvent) {
    // `touches` will be empty for start/end events so we have to fall back to `changedTouches`.
    const point = __isTouchEvent(event) ? (event.touches[0] || event.changedTouches[0]) : event;
    const scrollPosition = this.viewportRuler.getViewportScrollPosition();

    return {
      x: point.pageX - scrollPosition.left,
      y: point.pageY - scrollPosition.top
    };
  }
}

function __indexOf(collection, node) {
  return Array.prototype.indexOf.call(collection, node);
};

/** Determines whether an event is a touch event. */
function __isTouchEvent(event: MouseEvent | TouchEvent): event is TouchEvent {
  return event.type.startsWith('touch');
}

function __isInsideDropListClientRect(dropList: CdkDropList, x: number, y: number) {
  const { top, bottom, left, right } = dropList.element.nativeElement.getBoundingClientRect();
  return y >= top && y <= bottom && x >= left && x <= right;
}