import { TermsComponent } from './modules/terms/terms.component';
import { PrivacyPolicyComponent } from './modules/privacy-policy/privacy-policy.component';
import { NotFoundComponent } from './modules/404/not-found.component';
import { AuthGuard } from './_guards/auth.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InfluencerGuard } from './_guards/influencer.guard';

const routes: Routes = [
  {path: '', loadChildren:'./home/landing.module#LandingModule', pathMatch: 'full'},

  {path: 'privacy-policy', component: PrivacyPolicyComponent},
  {path: 'terms-of-service', component: TermsComponent},

  { path: '', loadChildren: './registration/registration.module#RegistrationModule'},

  // {path: 'core/confirm-email/:id', component: EmailConfirmationComponent},
  // {path: 'core/change-email/:id', component: ChangeEmailComponent},
  // {path: 'login', component: LoginComponent},
  // {path: 'brand/login', component: LoginSupplierComponent},
  // {path: 'core/confirm-password-reset/:uuid', component: RecoverPasswordComponent},

  {
    path: '',
    loadChildren: './main/shop/shop.module#ShopModule',
    canActivate: [AuthGuard, InfluencerGuard],
  },

  {
    path: 'home', 
    loadChildren: './main/home/home.module#UserHomeModule',
    // component: UserHomeComponent,
    canActivate: [AuthGuard, InfluencerGuard],
  },
  { path: '', loadChildren: './main/home-brand/brand.module#BrandModule'},
  // {path: 'brand', redirectTo: 'brand/categories', pathMatch: 'full'},
  // {path: 'brand/login', pathMatch: 'full', component: LoginSupplierComponent },
  // {path: 'brand/categories', component: CategoriesSupplierComponent, canActivate: [BrandGuard]},
  // {path: 'brand/category/:id', component: CategoryComponent, canActivate: [BrandGuard]},
  // {path: 'brand/confirm', component: ConfirmSupplierComponent, canActivate: [BrandGuard]},
  // {path: 'brand/archive', component: ArchiveComponent, canActivate: [BrandGuard]},
  // {path: 'brand/orders', component: OrdersComponent, canActivate: [BrandGuard]},
  // {path: 'brand/product/:id', component: ProductPreviewComponent, canActivate: [BrandGuard]},

  { path: '404', component: NotFoundComponent },
  { path: '**', redirectTo: '/404' }
];




@NgModule({
  imports: [RouterModule.forRoot(routes, {anchorScrolling: 'enabled', scrollPositionRestoration: 'top'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }



